import React from 'react'
import styled from 'styled-components'
import { Heading2, Paragraph } from '../atoms/Typography'
import { useAppState } from '../../GlobalState'
import { useWindowSize } from '../../hooks/WindowSize'
import { LiveEventLanding } from '../organisms/LiveEventLanding'
import { RegistrationOverlay } from '../organisms/RegistrationOverlay'
import { FullscreenContainer } from '../modal/Modal'
import { EventRegion, SidebarWidth } from '../../Constants'

const LiveEventContainer = styled(FullscreenContainer)`
  background-color: #000;
  flex-direction: row;
  @media (orientation: portrait) {
    flex-direction: column;
  }
`

const Text = styled(Paragraph)`
  font-size: 16px;
  line-height: 17px;
  ul {
    margin-top: 0.5em;
    padding-inline-start: 1em;
  }
`

const EventVimeo = styled.iframe`
  height: 100%;
  width: 100%;
`

const EventSidebarInfo = styled.div<{ width: number }>`
  width: ${p => p.width}px;
  max-width: 45vw;
  height: 100%;
  background-color: #000;
  color: #fff;
  padding: 0 32px 96px;
  box-sizing: border-box;
  overflow: scroll;
  @media (orientation: portrait) {
    width: 100%;
    max-width: none;
    padding: 0 32px 0;
  }
`

const SidebarChat = styled.iframe<{ w: number; h: number }>`
  width: ${p => p.w + 22}px;
  max-width: 45vw;
  height: 100%;
  background-color: #000;
  overflow: scroll;
  @media (orientation: portrait) {
    width: 100%;
    max-width: none;
  }
`

const InfoPrivateEvent = () => (
  <>
    <Heading2 uppercase>Beretta Holding’s Sako 100 years anniversary gala 2021</Heading2>
    <Text>
      The annual Beretta Holding’s Gala will take a look at how traditional handicraft and modern hi-tech have brought
      us to where we are today. And we’ll share our vision of continuing to aim for the future.
    </Text>
    <Text>
      For 100 years, we have believed that the best rifles and cartridges make the best hunters and target shooters.
      That is our history and our future.
    </Text>
    <Text>Beretta Holding’s Gala will consist of speeches, music performances and Distributor Awards Ceremony.</Text>
    <Heading2 uppercase>Programme</Heading2>
    <Text>
      Ceremonial speeches
      <ul>
        <li>Dr. Franco Gussalli Beretta</li>
        <li>Raimo Karjalainen, General Manager, Sako Ltd.</li>
      </ul>
    </Text>
    <Text>Music Performance: Ida Elina, Diandra & Waltteri Torikka</Text>
    <Text>Distributor Awards Ceremony</Text>
    <Text>
      Sako 100 Anniversary year <em>(showroom launch and Sako 100 documentary film)</em>
    </Text>
  </>
)
const Info = () => (
  <>
    <Heading2 uppercase>Sako 100 birthday celebration event</Heading2>
    <Text>
      For 100 years, we have believed that the combination of the most accurate, best-in class rifles and cartridges
      make the best hunters and target shooters. That is our history and our future. Now, it’s time to come together to
      celebrate.
    </Text>
    <Text>
      We will take a look at how traditional handicraft and modern hi-tech have brought us to where we are today. And
      we’ll share our vision of continuing to aim for the future.
    </Text>
    <Heading2 uppercase>Programme</Heading2>
    <Text>
      Ceremonial speeches
      <ul>
        <li>Dr. Franco Gussalli Beretta</li>
        <li>Raimo Karjalainen, General Manager, Sako Ltd.</li>
      </ul>
    </Text>
    <Text>Music Performance: Ida Elina, Diandra & Waltteri Torikka</Text>
    <Text>Sako Showroom launch</Text>
    <Text>The world premiere of the SAKO’s 100 Year Documentary film</Text>
  </>
)

export const LiveEvent = (): JSX.Element | null => {
  const { state } = useAppState()
  const { height } = useWindowSize()

  const vimeoSrc = process.env[`REACT_APP_VIMEO_${state.eventRegion?.toUpperCase()}`]
  const chatSrc = process.env[`REACT_APP_VIMEO_CHAT_${state.eventRegion?.toUpperCase()}`]

  if (!state.eventRegion) {
    return <LiveEventLanding />
  }
  if (!state.registered) {
    return <RegistrationOverlay />
  }
  return (
    <LiveEventContainer height={height}>
      <EventVimeo
        src={vimeoSrc}
        frameBorder='0'
        allow='autoplay; fullscreen; picture-in-picture'
        allowFullScreen
      ></EventVimeo>
      {state.sidebar === 'chat' && (
        <SidebarChat src={chatSrc} frameBorder='0' w={SidebarWidth} h={height}></SidebarChat>
      )}
      {state.sidebar === 'info' && (
        <EventSidebarInfo width={SidebarWidth}>
          {state.eventRegion === EventRegion.Private && <InfoPrivateEvent />}
          {state.eventRegion === EventRegion.EU && <Info />}
          {state.eventRegion === EventRegion.US && <Info />}
        </EventSidebarInfo>
      )}
    </LiveEventContainer>
  )
}
