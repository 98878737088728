import React from 'react'

export const Letter = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width='22px' height='16px' viewBox='0 0 22 16' {...props}>
    <g>
      <g transform='translate(-554.000000, -1282.000000)' fill='#484848' fillRule='nonzero'>
        <g transform='translate(280.000000, 88.000000)'>
          <g transform='translate(274.000000, 1192.000000)'>
            <g transform='translate(0.000000, 2.000000)'>
              <polygon points='11.05368 7.63797333 22 2.61205333 22 0 0 0 0 2.60416' />
              <polygon points='11.05918 10.5858133 0 5.54688 0 16 22 16 22 5.56245333' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
