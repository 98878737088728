import styled from 'styled-components'

export const Button = styled.button<{ shadow?: boolean }>`
  font-family: DINNextW01Medium, sans-serif;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  color: #fff;
  background-color: transparent;
  padding: 15px 40px 11px;
  border: 2px solid #fff;
  text-shadow: ${p => (p.shadow ? '1px 1px 0px #000' : 'none')};
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &:disabled {
    color: rgba(255, 255, 255, 0.5);
    border: 2px solid rgba(255, 255, 255, 0.5);
    cursor: not-allowed;
  }
  @media (max-width: 500px) {
    font-size: 18px;
    line-height: 18px;
    padding: 10px 20px 6px;
  }
`

export const SmallButton = styled(Button)`
  font-size: 14px;
  padding: 4px 40px 2px;
`
