import React from 'react'
import { useWindowSize } from '../../hooks/WindowSize'

interface VimeoPlayerProps {
  autoPlay?: boolean
  videoSrc: string
}
export const VimeoPlayer = (props: VimeoPlayerProps): JSX.Element => {
  const { height } = useWindowSize()

  return (
    <>
      <iframe
        src={props.videoSrc + (props.autoPlay ? '?autoplay=1' : '')}
        frameBorder='0'
        allow='autoplay; fullscreen; picture-in-picture'
        allowFullScreen
        style={{ height, width: '100%' }}
      ></iframe>
    </>
  )
}
