import React from 'react'
import styled from 'styled-components'
import { useAppState } from '../../GlobalState'
import { Button } from '../atoms/Button'
import { Heading, HeroText } from '../atoms/Typography'
import { NativeVideo } from './NativeVideo'
import droneVideo from '../../assets/drone.mp4'

const WelcomeBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  z-index: 2010;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
    @media (min-aspect-ratio: 16/9) {
      height: 56.25vw;
    }
    @media (max-aspect-ratio: 16/9) {
      height: 177.78vw;
    }
  }
`

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: scroll;
`

const WelcomeContent = styled.div`
  color: #fff;
  max-width: 880px;
  width: 80vw;
  text-align: center;
  margin: 128px auto 0;

  Button {
    margin-top: 16px;
    margin-bottom: 64px;
  }
`

const Text = styled(HeroText)`
  font-family: DINNextW01Regular;
  line-height: 28px;
  font-style: normal;
`

export const WelcomeOverlayWithVideo = (): JSX.Element | null => {
  const { state, actions } = useAppState()
  const exit = () => {
    if (state.panoLoading) return // ignore clicks where panorama is still loading
    actions.setWelcomeScreen(false)
  }
  if (!state.welcomeScreen) return null
  if (state.panoLoading) return null
  return (
    <WelcomeBackdrop onClick={exit}>
      {/* <YouTubePlayer mute loop videoId='ftgX04OOmNw' /> */}
      <NativeVideo autoPlay muted playsInline src={droneVideo} />
      <Container>
        <WelcomeContent>
          <Heading uppercase>Sako 100 years anniversary — building the future</Heading>
          <Text>
            The core of the Sako brand is the unique combination of people and technology. From the craft of riflesmiths
            of the 1920s to the latest innovations of today, we have always demanded perfection in everything we do.
          </Text>
          <Text>
            We acknowledge the past, but look to the future. We live and breathe this lifestyle, always looking for ways
            to improve. Our rich legacy and deep knowledge of the hunting lifestyle combined with constant feedback from
            our customers is the key to our future.
          </Text>
          <Text>
            It’s not just about technology, it’s about people and understanding the needs and desires of our fellow
            shooters. We are not predicting the future, we are building it together.
          </Text>
          <Text>
            Now we invite you into our showroom, a museum space that is part of the old Sako factory. It’s a unique
            opportunity to dive into who we are and what life is like at Sako.
          </Text>
          <Button onClick={exit}>Enter the Sako showroom</Button>
        </WelcomeContent>
      </Container>
    </WelcomeBackdrop>
  )
}
