import styled from 'styled-components'

export const TextField = styled.input`
  font-family: DINNextW01Regular, sans-serif;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 14px 20px 11px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 100%;
  box-sizing: border-box;
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  &:focus {
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: none;
    border-color: rgba(255, 255, 255, 0.8);
    outline: none;
  }
  &::placeholder {
    color: #fff;
    opacity: 1;
  }
`
