import React, { useState } from 'react'
import styled from 'styled-components'
import Cookies from 'js-cookie'
import { Link } from '../atoms/Typography'
import { SmallButton } from '../atoms/Button'

const Container = styled.div`
  z-index: 2024;
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #eee;
  text-align: center;
  font-size: 11px;
  line-height: 11px;
  @media (min-width: 480px) {
    font-size: 14px;
    line-height: 16px;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 1024px) {
  }
`

const AgreeButton = styled(SmallButton)`
  margin-top: 1em;
`

export const CookieConsent = (): JSX.Element | null => {
  const name = 'cookieconsent'
  const value = Cookies.get(name)
  const [show, setShow] = useState(!value)

  const agree = () => {
    Cookies.set(name, '1', { expires: 2 })
    setShow(false)
  }

  if (!show) {
    return null
  }
  return (
    <Container>
      <div>
        This website stores cookies on your computer. These cookies are used to collect information about interact with
        our website and allow us to remember you. We use this information in order to improve and customize your
        browsing experience and for analytics and metrics about our visitors both on this website and other media. To
        find out more about the cookies we use, see our{' '}
        <Link href='https://www.sako.fi/privacy-policy' target='sakofi'>
          Privacy Policy
        </Link>
        .
      </div>
      <AgreeButton onClick={agree}>Agree</AgreeButton>
    </Container>
  )
}
