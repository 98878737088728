import React from 'react'
import styled from 'styled-components'
import { ProductDetail } from '../../content/Content'
import { Heading2 } from '../atoms/Typography'

const Container = styled.div<{ right: boolean }>`
  padding: 16px 0 16px;
  display: grid;
  align-items: center;

  justify-items: ${p => (p.right ? 'right' : 'left')};
  grid-template-rows: auto;
  grid-template-columns: ${p => (p.right ? 'auto 164px' : '164px auto')};
  grid-template-areas: ${p => (p.right ? "'content image'" : "'image content'")};
  grid-gap: 32px;

  @media (max-width: 920px) {
    padding: 0 0 64px;
    justify-items: center;
    grid-template-columns: none;
    grid-template-areas: 'image' 'content';
    grid-gap: 0;
  }
`

const DetailImage = styled.div<{ url: string }>`
  grid-area: image;
  display: inline-block;
  width: 164px;
  height: 164px;
  background-color: #333;
  background-image: url(${p => p.url});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
`

const DetailContent = styled.div<{ right?: boolean }>`
  grid-area: content;
  text-align: ${p => (p.right ? 'right' : 'left')};
  @media (max-width: 920px) {
    text-align: center;
  }
`

export const DetailParagraph = styled.div`
  font-family: Georgia, serif;
  font-style: italic;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1em;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`

interface ProductDetailRowProps {
  data: ProductDetail
  index: number
}
export const ProductDetailRow = (props: ProductDetailRowProps): JSX.Element => {
  const right = props.index % 2 === 1
  return (
    <Container right={right}>
      <DetailImage url={props.data.image} />
      <DetailContent right={right}>
        <Heading2 uppercase>{props.data.heading}</Heading2>
        <DetailParagraph>{props.data.description}</DetailParagraph>
      </DetailContent>
    </Container>
  )
}
