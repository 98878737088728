import React, { FormEvent, useState } from 'react'
import styled from 'styled-components'
import validator from 'validator'
import { useAppState } from '../../GlobalState'
import { Button } from '../atoms/Button'
import { TextField } from '../atoms/Input'
import { Heading, HeroText } from '../atoms/Typography'
import ExpiredStorage from 'expired-storage'
import { CountdownLayer } from './CountdownLayer'
import { EventRegion } from '../../Constants'

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  z-index: 2010;
  display: flex;
  justify-content: center;
  overflow: scroll;
`

const Content = styled.div`
  color: #fff;
  max-width: 720px;
  width: 80vw;
  margin-top: 128px;
  padding: 0 32px 0;
  text-align: center;
`

const FormGrid = styled.div`
  display: grid;
  grid-template: auto / 1fr;
  column-gap: 20px;
  row-gap: 20px;
  text-align: left;
  padding: 20px 20px 40px;
  max-width: 480px;
  margin: 0 auto;
`

const ErrorMessage = styled.div`
  color: #a33;
  font-size: 18px;
`

const Padding = styled.div<{ amount: string }>`
  padding-top: ${p => p.amount};
`

export const RegistrationOverlay = (): JSX.Element | null => {
  const showForm = false // note: whole form is now hidden since it was not needed after all
  const { state, actions } = useAppState()
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')

  const nameChanged = (e: FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value)
  }
  const emailChanged = (e: FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value)
  }
  const companyChanged = (e: FormEvent<HTMLInputElement>) => {
    setCompany(e.currentTarget.value)
  }
  const hasError = () => {
    if (name.length < 3) return 'Name is required field'
    if (email.length < 1) return 'Email is required field'
    if (!validator.isEmail(email)) return 'Email is not valid'
    return ''
  }
  const submit = () => {
    const error = showForm ? hasError() : ''
    setError(error)
    if (!error) {
      if (showForm) {
        const es = new ExpiredStorage()
        es.setJson('registered', { name, email, company }, 60 * 60 * 12) // 12 hours
      }
      actions.setRegistered(true)
    }
  }

  return (
    <Backdrop>
      <Content>
        {state.eventRegion === EventRegion.Private ? (
          <>
            <Heading uppercase>Beretta Holding’s Sako 100 years anniversary gala 2021</Heading>
            <CountdownLayer />
            <HeroText>
              For 100 years, we have believed that the best rifles and cartridges make the best hunters and target
              shooters. That is our history and our future. Now, it’s time to come together to celebrate. We will take a
              look at how traditional handicraft and modern hi-tech have brought us to where we are today. And we’ll
              share our vision of continuing to aim for the future.
            </HeroText>
          </>
        ) : (
          (state.eventRegion === EventRegion.EU || state.eventRegion === EventRegion.US) && (
            <>
              <Heading uppercase>Sako 100 birthday celebration event</Heading>
              <CountdownLayer />
              <HeroText>
                For 100 years, we have believed that the best rifles and cartridges make the best hunters and target
                shooters. That is our history and our future. Now, it’s time to come together to celebrate. We will take
                a look at how traditional handicraft and modern hi-tech have brought us to where we are today. And we’ll
                share our vision of continuing to aim for the future.
              </HeroText>
            </>
          )
        )}
        {showForm && (
          <FormGrid>
            <div
              style={{
                fontFamily: 'georgia, serif',
                fontStyle: 'italic',
              }}
            >
              To proceed, please enter the following:
            </div>
            <div>
              <TextField
                type='text'
                placeholder='NAME*'
                name='name'
                autoComplete='name'
                value={name}
                onChange={nameChanged}
              />
            </div>
            <div>
              <TextField
                type='text'
                placeholder='EMAIL*'
                name='email'
                autoComplete='email'
                value={email}
                onChange={emailChanged}
              />
            </div>
            <div>
              <TextField
                type='text'
                placeholder='COMPANY'
                name='company'
                autoComplete='organization'
                value={company}
                onChange={companyChanged}
              />
            </div>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </FormGrid>
        )}
        <Padding amount='16px' />
        <Button onClick={submit} disabled={showForm && !!hasError()}>
          Go!
        </Button>
        <Padding amount='25vh' />
      </Content>
    </Backdrop>
  )
}
