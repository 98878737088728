import React, { SVGProps } from 'react'

export const LogoSince1921 = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width='122px' height='32px' viewBox='0 0 122 32' {...props}>
    <g transform='translate(-32.000000, -16.000000)' fill='currentColor' fillRule='nonzero'>
      <g transform='translate(32.000000, 16.000000)'>
        <path d='M68.0538182,30.4772414 C68.456217,30.933786 69.0299094,31.2052028 69.6398182,31.2275862 C69.9517527,31.2572064 70.2617248,31.1541633 70.4931079,30.9439296 C70.7244909,30.7336959 70.8556902,30.4358926 70.8542727,30.1241379 C70.8542727,28.6124138 67.8264545,28.9158621 67.8264545,27.0234483 C67.8264545,26.1682759 68.564,25.4565517 69.7008182,25.4565517 C70.2941818,25.4565517 71.3644545,25.7103448 71.3644545,26.5103448 L71.3644545,26.9462069 L70.8099091,26.9462069 L70.8099091,26.6593103 C70.8099091,26.2896552 70.3052727,26.0248276 69.7396364,26.0248276 C68.9410909,26.0248276 68.4808182,26.4827586 68.4808182,27.0068966 C68.4808182,28.4193103 71.5086364,28.0717241 71.5086364,30.0910345 C71.4965755,30.5604872 71.2904127,31.0042007 70.9387905,31.3174842 C70.5871684,31.6307677 70.1212296,31.785874 69.6509091,31.7462069 C68.8971224,31.7731619 68.1687015,31.4732105 67.6545455,30.9241379 L68.0538182,30.4772414 Z' />
        <polygon points='73.2942727 31.1503448 73.8488182 31.1503448 73.8488182 26.1186207 73.2942727 26.1186207 73.2942727 25.5668966 75.0521818 25.5668966 75.0521818 26.1186207 74.4643636 26.1186207 74.4643636 31.1503448 75.0521818 31.1503448 75.0521818 31.702069 73.2942727 31.702069' />
        <path d='M76.8710909,31.1503448 L77.2426364,31.1503448 C77.2955468,31.155562 77.3480206,31.1369001 77.3856172,31.0994948 C77.4232138,31.0620894 77.4419711,31.0098826 77.4367273,30.9572414 L77.4367273,25.577931 L77.9912727,25.577931 L81.1244545,29.9310345 C81.3351818,30.2344828 81.6235455,30.7310345 81.6235455,30.7310345 L81.6235455,30.7310345 C81.6235455,30.7310345 81.5791818,30.2510345 81.5791818,29.9310345 L81.5791818,26.1075862 C81.5791818,25.7324138 81.74,25.5558621 82.1337273,25.5558621 L82.7825455,25.5558621 L82.7825455,26.1075862 L82.4054545,26.1075862 C82.3531144,26.1005098 82.3004101,26.1177657 82.2625226,26.1543835 C82.2246351,26.1910013 82.2057709,26.2429154 82.2113636,26.2951724 L82.2113636,31.6744828 L81.6568182,31.6744828 L78.5180909,27.3213793 C78.3018182,27.0289655 78.0134545,26.5324138 78.0134545,26.5324138 L78.0134545,26.5324138 C78.0134545,26.5324138 78.0633636,27.0013793 78.0633636,27.3213793 L78.0633636,31.1448276 C78.0633636,31.52 77.9080909,31.6965517 77.5088182,31.6965517 L76.8655455,31.6965517 L76.8710909,31.1503448 Z' />
        <path d='M87.4961818,25.4730469 C88.3723636,25.4730469 89.792,25.8482759 89.792,26.7696552 L89.792,27.2717241 L89.2374545,27.2717241 L89.2374545,26.9351724 C89.2374545,26.3834483 88.2448182,26.0468966 87.535,26.0468966 C86.8719426,26.0489223 86.2388175,26.3217789 85.7837909,26.8016114 C85.3287643,27.281444 85.0917709,27.9261409 85.1282727,28.5848276 C85.0905684,29.2574986 85.3280264,29.9167284 85.7865934,30.4124542 C86.2451604,30.90818 86.8858167,31.1982201 87.5627273,31.2165517 C88.3152089,31.202621 89.0305237,30.8885296 89.548,30.3448276 L89.8973636,30.7917241 C89.279486,31.429496 88.4253049,31.7865864 87.535,31.7793103 C86.6945519,31.7787947 85.8910101,31.4357374 85.3115738,30.8300597 C84.7321374,30.2243821 84.427403,29.4089719 84.4683636,28.5737931 C84.4396519,27.7583572 84.74648,26.9665473 85.3179507,26.3813229 C85.8894213,25.7960985 86.6760888,25.4680953 87.4961818,25.4730469 L87.4961818,25.4730469 Z' />
        <path d='M92.1044545,26.1186207 L91.5499091,26.1186207 L91.5499091,25.5668966 L95.0601818,25.5668966 C95.4372727,25.5668966 95.6147273,25.7213793 95.6147273,26.1186207 L95.6147273,26.7255172 L95.0601818,26.7255172 L95.0601818,26.3172414 C95.0657746,26.2649844 95.0469104,26.2130703 95.0090229,26.1764525 C94.9711353,26.1398347 94.918431,26.1225788 94.8660909,26.1296552 L92.7699091,26.1296552 L92.7699091,28.3365517 L95.0768182,28.3365517 L95.0768182,28.8882759 L92.7699091,28.8882759 L92.7699091,30.9627586 C92.7646652,31.0153999 92.7834226,31.0676067 92.8210192,31.105012 C92.8586158,31.1424174 92.9110896,31.1610793 92.964,31.1558621 L95.1211818,31.1558621 C95.1737061,31.1614264 95.2258856,31.1426582 95.2626906,31.1049634 C95.2994957,31.0672686 95.3168398,31.0148324 95.3097273,30.9627586 L95.3097273,30.5544828 L95.8642727,30.5544828 L95.8642727,31.1613793 C95.8642727,31.5365517 95.7034545,31.7131034 95.3097273,31.7131034 L92.6534545,31.7131034 C92.2763636,31.7131034 92.0989091,31.5586207 92.0989091,31.1613793 L92.1044545,26.1186207 Z' />
        <path d='M101.243364,31.1503448 L102.590909,31.1503448 L102.590909,26.6648276 C102.590909,26.4882759 102.590909,26.3282759 102.590909,26.3282759 L102.590909,26.3282759 C102.508532,26.464441 102.405672,26.5872452 102.285909,26.6924138 L101.626,27.3434483 L101.243364,26.9517241 L102.635273,25.577931 L103.189818,25.577931 L103.189818,31.1503448 L104.537364,31.1503448 L104.537364,31.702069 L101.210091,31.702069 L101.243364,31.1503448 Z' />
        <path d='M106.716727,31.0068966 C106.992787,31.1491892 107.29865,31.2247935 107.609545,31.2275862 C108.890545,31.2275862 109.556,29.942069 109.661364,28.7062069 L109.661364,28.7062069 C109.300206,29.2015855 108.71187,29.4818032 108.097545,29.4510345 C107.579938,29.4689697 107.078677,29.2691674 106.716696,28.9006292 C106.354716,28.532091 106.165501,28.0289066 106.195455,27.5144828 C106.151593,26.9840026 106.334969,26.4597661 106.700382,26.0709955 C107.065794,25.6822248 107.579249,25.4650862 108.114182,25.4731034 C109.450636,25.4731034 110.332364,26.6648276 110.332364,28.2648276 C110.332364,29.8648276 109.539364,31.7903597 107.637273,31.7903597 C107.252885,31.7916682 106.873363,31.7048223 106.528182,31.5365517 L106.716727,31.0068966 Z M109.661364,27.8731034 C109.661364,27.062069 109.023636,26.0413793 108.108636,26.0413793 C107.344058,26.102747 106.76038,26.7469167 106.778043,27.5098743 C106.795706,28.2728319 107.408574,28.8895822 108.175182,28.9158621 C108.995909,28.9158621 109.661364,28.3696552 109.661364,27.8731034 Z' />
        <path d='M115.267818,27.1944828 C115.269178,26.8710595 115.1333,26.5620808 114.893622,26.3435814 C114.653944,26.125082 114.332721,26.01735 114.009,26.0468966 C113.399,26.0468966 112.899909,26.3613793 112.899909,26.7089655 L112.899909,26.9958621 L112.345364,26.9958621 L112.345364,26.5434483 C112.345364,25.7710345 113.399,25.4731034 114.009,25.4731034 C114.490267,25.4312373 114.967029,25.5927762 115.32258,25.9181774 C115.678132,26.2435786 115.879654,26.7028066 115.877818,27.1834483 C115.877818,29.3296552 112.633727,29.28 112.633727,30.9737931 C112.633727,31.0951724 112.700273,31.1503448 112.833364,31.1503448 L115.400909,31.1503448 C115.453433,31.1559092 115.505613,31.1371409 115.542418,31.0994461 C115.579223,31.0617514 115.596567,31.0093152 115.589455,30.9572414 L115.589455,30.5489655 L116.144,30.5489655 L116.144,31.1558621 C116.144,31.5310345 115.983182,31.7075862 115.589455,31.7075862 L112.572727,31.7075862 C112.156818,31.7075862 112.018182,31.5365517 112.018182,31.1062069 C112.018182,28.822069 115.267818,29.0041379 115.267818,27.1944828 Z' />
        <path d='M118.118182,31.1503448 L119.493455,31.1503448 L119.493455,26.6648276 C119.493455,26.4882759 119.493455,26.3282759 119.493455,26.3282759 L119.493455,26.3282759 C119.411078,26.464441 119.308218,26.5872452 119.188455,26.6924138 L118.534091,27.3434483 L118.145909,26.9517241 L119.537818,25.577931 L120.092364,25.577931 L120.092364,31.1503448 L121.445455,31.1503448 L121.445455,31.702069 L118.118182,31.702069 L118.118182,31.1503448 Z' />
        <polygon points='68.0094545 21.1089655 68.0094545 7.28827586 65.7025455 7.28827586 72.1241818 0.215172414 72.1241818 21.1089655' />
        <path d='M96.9511832,10.8910345 C96.9511832,15.2965487 94.2834964,19.2681637 90.1923039,20.9535651 C86.1011114,22.6389665 81.392294,21.7061642 78.2619886,18.590206 C75.1316832,15.4742479 74.196497,10.7889152 75.8925897,6.71939166 C77.5886825,2.64986814 81.5819582,-0.00223177012 86.01,-1.40745122e-06 C88.9127429,-1.40745122e-06 91.696516,1.14762066 93.7485451,3.19024458 C95.8005742,5.23286849 96.9526531,8.00306 96.9511832,10.8910345 Z M86.01,4.01103448 C82.1908505,4.01103448 79.0948182,7.0913154 79.0948182,10.8910345 C79.0948182,14.6907536 82.1908505,17.7710345 86.01,17.7710345 C89.8291495,17.7710345 92.9251818,14.6907536 92.9251818,10.8910345 C92.9251818,9.06634503 92.1966201,7.31639011 90.899772,6.02613983 C89.6029238,4.73588954 87.8440202,4.01103448 86.01,4.01103448 Z' />
        <path d='M121.783727,10.8910345 C121.783727,16.9059867 116.882711,21.782069 110.837,21.782069 C104.791289,21.782069 99.8902727,16.9059867 99.8902727,10.8910345 C99.8902727,4.87608223 104.791289,0 110.837,0 C116.882711,0 121.783727,4.87608223 121.783727,10.8910345 Z M110.837,4.01103448 C107.017851,4.01103448 103.921818,7.0913154 103.921818,10.8910345 C103.921818,14.6907536 107.017851,17.7710345 110.837,17.7710345 C114.656149,17.7710345 117.752182,14.6907536 117.752182,10.8910345 C117.752182,9.06634503 117.02362,7.31639011 115.726772,6.02613983 C114.429924,4.73588954 112.67102,4.01103448 110.837,4.01103448 L110.837,4.01103448 Z' />
        <path d='M57.118199,16.9158621 C57.119669,17.1562878 57.0243293,17.3872949 56.8534501,17.5573048 C56.6825708,17.7273147 56.4503825,17.8221692 56.2087273,17.8207067 L51.1568182,17.8207067 C50.6545403,17.8207067 50.2473636,17.4155845 50.2473636,16.9158621 L50.2473636,12.3310345 C50.250391,11.8325649 50.6557996,11.4292189 51.1568182,11.4262069 L56.1976364,11.4262069 C56.4407546,11.4232569 56.6749374,11.5172828 56.8478995,11.6872917 C57.0208617,11.8573005 57.118199,12.0891351 57.118199,12.3310345 L57.118199,16.9158621 Z M59.2864545,7.80689655 L48.1123636,7.80689655 C47.1363636,7.80689655 46.0272727,8.61241379 46.0272727,9.61103448 L46.0272727,19.6468966 C46.0272727,20.6455172 47.0864545,21.4510345 48.0901818,21.4510345 L59.2531818,21.4510345 C60.2569091,21.4510345 61.3160909,20.6455172 61.3160909,19.6468966 L61.3160909,9.61103448 C61.3382727,8.61241379 60.2790909,7.80689655 59.2753636,7.80689655 L59.2864545,7.80689655 Z' />
        <path d='M4.24781818,11.4151724 L15.128,11.4151724 L15.128,7.80689655 L2.06290909,7.80689655 C1.05918182,7.80689655 0,8.61793103 0,9.61655172 L0,14.6151724 C0.0972717264,15.5708235 0.862798659,16.3231957 1.82445455,16.4082759 L10.431,16.4082759 C10.794328,16.4404471 11.0728402,16.7432986 11.0728402,17.1062069 C11.0728402,17.4691152 10.794328,17.7719667 10.431,17.8041379 L10.3755455,17.8041379 C10.3755455,17.8041379 10.3755455,17.8041379 10.3755455,17.8041379 L0,17.8041379 L0,21.4565517 L13.0650909,21.4565517 C14.0688182,21.4565517 15.128,20.6455172 15.128,19.6468966 L15.128,14.6151724 C15.128,13.6606897 14.1575455,12.8772414 13.1926364,12.8165517 L4.24781818,12.8165517 C3.85885909,12.8165517 3.54354545,12.5028423 3.54354545,12.1158621 C3.54354545,11.7288819 3.85885909,11.4151724 4.24781818,11.4151724 Z' />
        <path d='M29.1191818,7.80137931 L16.0097273,7.80137931 L16.0097273,11.4151724 L26.2632727,11.4151724 C26.6415781,11.4179497 26.9500948,11.7175886 26.962,12.0937931 L26.962,12.8165517 L18.0449091,12.8165517 C17.0522727,12.8165517 16.0152727,13.6331034 16.0152727,14.6206897 L16.0152727,19.657931 C16.0152727,20.6565517 17.0744545,21.4675862 18.0781818,21.4675862 L31.1820909,21.4675862 L31.1820909,9.61103448 C31.1820909,8.61241379 30.1229091,7.80137931 29.1191818,7.80137931 Z M26.962,17.8041379 L20.3074545,17.8041379 C20.0419417,17.827648 19.7858407,17.7000022 19.6457885,17.4743504 C19.5057362,17.2486986 19.5057362,16.9637152 19.6457885,16.7380634 C19.7858407,16.5124116 20.0419417,16.3847658 20.3074545,16.4082759 L26.962,16.4082759 L26.962,17.8041379 Z' />
        <polygon points='46.1714545 7.79034483 40.4097273 7.79034483 36.2617273 12.1655172 36.2617273 0.551724138 32.0693636 0.551724138 32.0693636 21.4510345 36.2617273 21.4510345 36.2617273 18.24 37.7922727 16.6234483 41.1029091 21.4731034 46.1714545 21.4731034 40.7369091 13.5172414' />
      </g>
    </g>
  </svg>
)

export const TikkaLogo = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg height={88} viewBox='0 0 245 88' width={245} {...props}>
    <path
      d='m17.7384103 19.1741833-7.0952275-12.62151398h77.3835206l-7.0955689 12.62151398h-16.2342931v6.3107569h12.6866793l-24.9763345 44.4280797v-44.4280797l-3.0724138-6.3107569-3.0724138 6.3107569v44.4280797l-24.9759931-44.4280797h12.6863379v-6.3107569m57.6016138-18.93227095h-91.5743172l49.3347724 87.75808765 49.3351138-87.75808765z'
      fill='#e5722a'
      mask='url(#a)'
    />
    <g fill='#1a1919'>
      <path d='m110.916667 27.1489362v6.2978723h9.28125v28.3404255h6.1875v-28.3404255h9.28125v-6.2978723z' />
      <path d='m140.25 61.787234h6.416667v-34.6382978h-6.416667z' />
      <path d='m225.768459 46.0425532 3.398036-9.525182 3.398036 9.525182zm6.682564-18.7323215-.057611-.1612955h-6.453491l-12.356588 34.6382978h6.568714l3.369916-9.4468085h11.289408l3.369916 9.4468085h6.568713z' />
      <path d='m180.583333 27.1489362h-8.647166l-12.738401 13.1205673v-13.1205673h-6.114433v34.6382978h6.114433v-13.0184018l12.639211 13.0184018h8.647167l-16.765095-17.2680662z' />
      <path d='m210.833333 27.1489362h-8.647166l-12.738401 13.1205673v-13.1205673h-6.114433v34.6382978h6.114433v-13.0184018l12.639211 13.0184018h8.647167l-16.765095-17.2680662z' />
    </g>
  </svg>
)
