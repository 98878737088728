import styled from 'styled-components'
import TagManager from 'react-gtm-module'

export const SalesforceForm = styled.div`
  form {
    text-align: left;
    font-family: DINNextW01Regular, sans-serif;
    font-size: 18px;

    fieldset {
      max-width: 100%;
      input.hs-input,
      select.hs-input {
        padding: 10px;
        background-color: #000;
        border: 2px solid #fff;
        color: #fff;
        font-size: 16px;
        margin: 8px 0 16px;
        &::placeholder {
          color: #fff;
        }
      }
      li.hs-form-radio input[type='radio'] {
        margin: 0 16px 16px 0;
      }
    }
    ul {
      list-style-type: none;
      padding-inline-start: 0;
    }
    ul.hs-error-msgs {
      padding: 0;
      margin: 0 0 32px;
      label {
        color: #f66;
      }
    }
    a {
      color: #fff;
    }

    input[type='submit'] {
      background-color: transparent;
      border: 2px solid #fff;
      color: #fff;
      padding: 15px 40px 11px;
      font-family: DINNextW01Medium, sans-serif;
      font-size: 22px;
      text-transform: uppercase;
      margin: 16px 0;
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
`

export const createSalesforceForm = (targetId: string, formId: string, onSubmitted: () => void): void => {
  hbspt.forms.create({
    portalId: '436214',
    formId,
    sfdcCampaignId: '70108000000tXqkAAE',
    target: '#' + targetId,
    onBeforeFormInit: (ctx: any) => {
      console.log('onBeforeFormInit', ctx)
    },
    onFormReady: (form: any) => {
      console.log('onFormReady', form)
    },
    onFormSubmit: (form: any) => {
      console.log('onFormSubmit', form)
    },
    onFormSubmitted: () => {
      console.log('onFormSubmitted')
      TagManager.dataLayer({ dataLayer: { event: 'SalesforceFormSubmit', contentId: formId } })
      onSubmitted()
    },
  })
}
