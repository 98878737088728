import React from 'react'
import styled from 'styled-components'
import { ProductGridData, ProductGridDataGun } from '../../content/Content'

const Container = styled.div`
  background-color: #000;
  width: 100%;
  padding: 0 0 32px;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
`

const DetailImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
`

const ProductLabel = styled.div<{ uppercase?: boolean }>`
  font-family: DINNextW01Medium, sans-serif;
  text-transform: ${p => (p.uppercase ? 'uppercase' : 'none')};
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  margin-top: -50px;
  text-align: center;
`

const Product = styled.div`
  padding: 32px 0;
`

interface ProductListProps {
  data: ProductGridData[]
}
export const ProductList = (props: ProductListProps): JSX.Element => {
  return (
    <Container>
      {props.data
        .filter(p => p.type === 'gun')
        .map(p => {
          const product = p as ProductGridDataGun
          return (
            <Product key={product.label}>
              <DetailImage src={product.image} />
              <ProductLabel uppercase>{product.label}</ProductLabel>
            </Product>
          )
        })}
    </Container>
  )
}
