export enum EventRegion {
  Private = 'private',
  EU = 'eu',
  US = 'us',
}

export enum Scene {
  Lobby = 'scene_cam05',
  Movie = 'scene_cam06',
  Exhibition = 'scene_cam07',
}

export enum ContentId {
  MooseVideo = 'moose-video',
  DocumentaryVideo = 'documentary-video',
  DocumentaryFinVideo = 'documentary-fin-video',
  TikkaT1xMTRVideo = 'tikka-t1x-mtr-video',
  TikkaT3xTactA1Video = 'tikka-t3x-tact-a1-video',
  PowerheadBladeVideo = 'powerhead-blade-video',
  LifelongJourneyVideo = 'lifelong-journey',
  TRGVideo = 'trg-video',
  S20AccessoriesVideo = 's20-accessories-video',
  S20CompleteStoryVideo = 's20-complete-story',
  SakoBrandVideo = 'sako-brand-video',
  Sako85HighlightsVideo = '85-hilights-video',
  Sako85CustomHighlightsVideo = '95-custom-hilights-video',
  TikkaBrandVideo = 'tikka-brand-video',
  BerettaEventVideo = 'beretta-event-video',
  AnniversaryEventVideo = 'anniversary-event-video',
  CartridgesMakesEveryRifleBetterVideo = 'cartridges-makes-better-video',
  CrafmanshipVideo = 'crafmanship-video',
  AccuracyVideo = 'accuracy-video',
  AnthemVideo = 'anthem-video',
}

export enum Hotspot {
  VideoGallery = 'video-gallery',
  LeftTvScreen = 'left-tv-video',
  RightTvScreen = 'right-tv-video',
  Moose = 'moose',
  MovieScreen = 'movie-screen',
  MovieScreenVideo = 'movie-screen-video',
  ExhibitionHistoric = 'exhibition-historic',
  ExhibitionS20 = 'exhibition-s20',
  ExhibitionSako85 = 'exhibition-sako85',
  ExhibitionTikka = 'exhibition-tikka',
  ExhibitionPremium = 'exhibition-premium',
  ExhibitionCelebrationModel = 'exhibition-celebration-model',
  ExhibitionTRG = 'exhibition-trg',
  ExhibitionCartridges = 'exhibition-cartridges',
  ExhibitionTvScreen = 'tv-video',
}

export interface SceneAndHotspot {
  scene: Scene
  hotspot?: Hotspot | string
}

export const HotspotScenes: SceneAndHotspot[] = [
  { hotspot: Hotspot.VideoGallery, scene: Scene.Movie },
  { hotspot: Hotspot.Moose, scene: Scene.Movie },
  { hotspot: Hotspot.MovieScreen, scene: Scene.Movie },
  { hotspot: Hotspot.ExhibitionHistoric, scene: Scene.Exhibition },
  { hotspot: Hotspot.ExhibitionS20, scene: Scene.Exhibition },
  { hotspot: Hotspot.ExhibitionSako85, scene: Scene.Exhibition },
  { hotspot: Hotspot.ExhibitionTikka, scene: Scene.Exhibition },
  { hotspot: Hotspot.ExhibitionPremium, scene: Scene.Exhibition },
  { hotspot: Hotspot.ExhibitionCelebrationModel, scene: Scene.Exhibition },
  { hotspot: Hotspot.ExhibitionCartridges, scene: Scene.Exhibition },
]

export const startCamera: SceneAndHotspot = { scene: Scene.Lobby, hotspot: undefined }
export const menuProductsCamera: SceneAndHotspot = { scene: Scene.Exhibition, hotspot: undefined }
export const menuVideosCamera: SceneAndHotspot = { scene: Scene.Movie, hotspot: Hotspot.VideoGallery }
export const menuDocumentaryCamera: SceneAndHotspot = { scene: Scene.Movie, hotspot: Hotspot.MovieScreen }

export const SidebarWidth = 500
