import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import TagManager from 'react-gtm-module'
import { Content, ProductButton, SalesforceFormId } from '../../content/Content'
import { Heading, Heading2, HeroText, Paragraph } from '../atoms/Typography'
import { CloseCircle } from '../../assets/Close'
import { YouTubePlayer } from '../organisms/YouTubePlayer'
import { VimeoPlayer } from '../organisms/VimeoPlayer'
import { DetailParagraph, ProductDetailRow } from '../organisms/ProductDetailRow'
import { ProductList } from '../organisms/ProductList'
import { ProductGrid } from '../organisms/ProductGrid'
import { Button } from '../atoms/Button'
import { useAppState } from '../../GlobalState'
import { Spinner } from '../atoms/Spinner'
import { BackArrow } from '../../assets/BackArrow'
import { Letter } from '../../assets/Letter'
import { createSalesforceForm, SalesforceForm } from '../organisms/SalesforceForm'
import { TikkaLogo } from '../../assets/Logos'
import { ContentId, Hotspot } from '../../Constants'
import s20Background2 from '../../content/images/s20-background-2.png'

const PopupBackground = styled.div<{ image?: string }>`
  background-repeat: no-repeat;
  background-image: ${p => (p.image ? `url(${p.image})` : 'none')};
  background-position: top center;
  background-size: contain;
`

const PopupContainer = styled.div<{ theme?: string; withTopImage?: boolean; noFade?: boolean }>`
  box-sizing: border-box;
  text-align: center;
  margin: auto;
  background-image: ${p =>
    p.noFade
      ? p.theme === 'themetikka'
        ? 'linear-gradient(transparent 320px, #fff 320px, #fff 320px)'
        : 'linear-gradient(#131313 0px, #131313 0px)'
      : p.theme === 'themetikka'
      ? 'linear-gradient(transparent 320px, #fff 320px)'
      : 'linear-gradient(transparent 250px, #131313 320px)'};
  color: ${p => (p.theme === 'themetikka' ? '#000' : '#fff')};
  padding: 48px 104px;
  padding-top: ${p => (p.withTopImage ? (p.theme === 'themetikka' ? '330px' : '272px') : '48px')};

  ${Heading}, ${Heading2}, ${Button} {
    color: ${p => (p.theme === 'themetikka' ? '#ff7200' : '#fff')};
    border-color: ${p => (p.theme === 'themetikka' ? '#ff7200' : '#fff')};
  }

  @media (max-width: 920px) {
    padding: 48px 48px;
    padding-top: ${p => (p.withTopImage ? '35vw' : '48px')};
    background-image: ${p =>
      p.noFade
        ? p.theme === 'themetikka'
          ? 'linear-gradient(transparent 35vw, #fff 35vw, #fff 35vw)'
          : 'linear-gradient(#131313 0px, #131313 0px)'
        : p.theme === 'themetikka'
        ? 'linear-gradient(transparent 35vw, #fff 35vw)'
        : 'linear-gradient(transparent 20vw, #131313 35vw)'};
  }
`

export const PopupClose = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  padding: 24px 24px 0 0;
  cursor: pointer;
`
export const PopupCloseLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  padding: 24px 0 0 24px;
  cursor: pointer;
`

const ButtonWrapper = styled.div`
  padding: 16px 0 32px;
  Button {
    margin-bottom: 32px;
  }
`

const ContentButton = ({ buttons }: { buttons?: ProductButton[] }) => {
  const { actions } = useAppState()
  if (!buttons || buttons.length === 0) return null
  const onClick = (button: ProductButton) =>
    button.type === 'internal'
      ? () => {
          console.log('popupTarget', button.popupTarget)
          actions.openPopup(button.popupTarget)
        }
      : () => {
          console.log('contentButton onClick', button.analyticsId)
          TagManager.dataLayer({ dataLayer: { event: 'OpenContent', contentId: button.analyticsId } })
          button.onClick && button.onClick()
        }
  return (
    <ButtonWrapper>
      {buttons.map(button => (
        <div key={button.label}>
          <Button onClick={onClick(button)}>{button.label}</Button>
        </div>
      ))}
    </ButtonWrapper>
  )
}

const SubscribeContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  & > span {
    font-size: 16px;
    margin-left: 12px;
    &:hover {
      text-decoration: underline;
    }
  }
`

const SubscribeButton = ({ subscribe }: { subscribe?: SalesforceFormId }) => {
  const { actions } = useAppState()
  if (!subscribe) return null
  return (
    <SubscribeContainer onClick={() => actions.openPopup(subscribe)}>
      <Letter />
      <span>SUBSCRIBE NOW FOR EXCLUSIVE CONTENT</span>
    </SubscribeContainer>
  )
}

const PopupCloser = ({ onClose, backArrow }: PopupContentProps) => {
  return backArrow ? (
    <PopupCloseLeft>
      <BackArrow onClick={onClose} />
    </PopupCloseLeft>
  ) : (
    <PopupClose>
      <CloseCircle onClick={onClose} />
    </PopupClose>
  )
}

const ContentHeading = (content: Partial<Content>) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {content.hero?.headingIcon === 'green-rectangle' && (
        <div style={{ width: '38px', height: '38px', backgroundColor: '#6faa4d', margin: '0 16px 12px 0' }} />
      )}
      {content.hero?.logo ? (
        <TikkaLogo style={{ padding: '32px 0 6px' }} />
      ) : (
        <Heading uppercase>{content.hero?.heading}</Heading>
      )}
    </div>
  )
}

const ContentImage = styled.img`
  margin: 48px 0 60px;
`

interface PopupContentProps {
  content?: Content
  onClose: () => void
  backArrow?: boolean
}
export const PopupContent = ({ content, onClose, backArrow }: PopupContentProps): JSX.Element | null => {
  const [targetId] = useState('sff-' + new Date().getTime() + '-' + String(Math.random()).slice(3))
  useEffect(() => {
    if (content && content.salesforceFormId) {
      console.debug('targetId ' + targetId)
      const formId = content.salesforceFormId
      const form = () =>
        createSalesforceForm(targetId, formId, () => {
          console.log('form was submitted')
        })
      setTimeout(form, 1000) // timeout to ensure that target element is already available
    }
  }, [])
  if (!content) return null
  if (content.salesforceFormId) {
    return (
      <PopupContainer>
        <ContentHeading {...content} />
        <SalesforceForm id={targetId}>
          <Spinner small />
        </SalesforceForm>
        <PopupCloser backArrow={backArrow} onClose={onClose} />
      </PopupContainer>
    )
  }
  if (content.id === Hotspot.ExhibitionS20) {
    // A BIT SPECIAL PAGE FOR s20
    return (
      <>
        <PopupBackground image={content.hero.backgroundImage}>
          <PopupContainer withTopImage={!!content.hero.backgroundImage}>
            <ContentHeading {...content} />
            <HeroText>{content.hero.text}</HeroText>
            <ContentButton
              buttons={[
                { type: 'internal', label: 'Complete story of Sako S20', popupTarget: ContentId.S20CompleteStoryVideo },
              ]}
            />
          </PopupContainer>
          <PopupCloser backArrow={backArrow} onClose={onClose} />
        </PopupBackground>
        <PopupBackground image={s20Background2}>
          <PopupContainer withTopImage={true}>
            <ContentHeading hero={{ heading: 'A Lifelong journey' }} />
            <HeroText>
              Sako S20 was designed to follow you on your journey. Wherever the path leads you, your rifle is always up
              to the task, just like you.
            </HeroText>
            <ContentButton
              buttons={[{ type: 'internal', label: 'Watch the film', popupTarget: ContentId.LifelongJourneyVideo }]}
            />
          </PopupContainer>
        </PopupBackground>
        <ProductList data={content.productGrid} />
        <PopupContainer noFade>
          <ContentButton
            buttons={[
              {
                type: 'internal',
                label: 'Watch accessories review',
                popupTarget: ContentId.S20AccessoriesVideo,
              },
              {
                type: 'external',
                label: 'Visit the configurator',
                onClick: () => window.open('https://s20.sako.fi/global', 'sakofi'),
                analyticsId: 'sako-s20-configurator',
              },
            ]}
          />
          <SubscribeButton subscribe={content.subscribe} />
        </PopupContainer>
      </>
    )
  }
  if (content.productGrid.length > 0) {
    return (
      <PopupBackground image={content.hero.backgroundImage}>
        <PopupContainer theme={content.theme} withTopImage={!!content.hero.backgroundImage}>
          <ContentHeading {...content} />
          {content.tagline && <Heading2 uppercase>{content.tagline}</Heading2>}
          <HeroText dangerouslySetInnerHTML={{ __html: content.hero.text! }} />
          <DetailParagraph>{content.body}</DetailParagraph>
          {content.hero.buttons && <ContentButton buttons={content.hero.buttons} />}
          <ProductGrid data={content.productGrid} />
          <ContentButton buttons={content.buttons} />
          <SubscribeButton subscribe={content.subscribe} />
        </PopupContainer>
        <PopupCloser backArrow={backArrow} onClose={onClose} />
      </PopupBackground>
    )
  }
  if (content.productDetails.length > 0) {
    return (
      <PopupBackground image={content.hero.backgroundImage}>
        <PopupContainer withTopImage={!!content.hero.backgroundImage}>
          <ContentHeading {...content} />
          <HeroText>{content.hero.text}</HeroText>
          <DetailParagraph>{content.body}</DetailParagraph>
          {content.productDetails.map((productDetail, i) => (
            <ProductDetailRow key={productDetail.heading} data={productDetail} index={i} />
          ))}
          <ContentButton buttons={content.buttons} />
          <SubscribeButton subscribe={content.subscribe} />
        </PopupContainer>
        <PopupCloser backArrow={backArrow} onClose={onClose} />
      </PopupBackground>
    )
  }
  if (content.youtube) {
    return <YouTubePlayer videoId={content.youtube} />
  }
  if (content.vimeo) {
    return <VimeoPlayer autoPlay={content.autoPlay} videoSrc={content.vimeo} />
  }
  if (content.gun) {
    const bg = content.gun.bgImage || content.hero.backgroundImage
    return (
      <PopupBackground image={bg}>
        <PopupContainer theme={content.theme} withTopImage={!!bg} noFade={content.theme === 'themetikka'}>
          <Heading uppercase>{content.gun.label}</Heading>
          {content.gun.tagline && <Heading2 uppercase>{content.gun.tagline}</Heading2>}
          {content.gun.year && <HeroText>{content.gun.year}</HeroText>}
          <HeroText dangerouslySetInnerHTML={{ __html: content.gun.description! }} />
          {content.gun.button && <ContentButton buttons={[content.gun.button]} />}
        </PopupContainer>
        <PopupCloser backArrow={backArrow} onClose={onClose} />
      </PopupBackground>
    )
  }
  return (
    <PopupBackground image={content.hero.backgroundImage}>
      <PopupContainer withTopImage={!!content.hero.backgroundImage}>
        <ContentHeading {...content} />
        {content.hero.buttons && <ContentButton buttons={content.hero.buttons} />}
        <HeroText dangerouslySetInnerHTML={{ __html: content.hero.text! }} />
        <Paragraph>{content.body}</Paragraph>
        {content.image && <ContentImage src={content.image} />}
        {content.youtube && <YouTubePlayer videoId={content.youtube} />}
        <ContentButton buttons={content.buttons} />
        <SubscribeButton subscribe={content.subscribe} />
      </PopupContainer>
      <PopupCloser backArrow={backArrow} onClose={onClose} />
    </PopupBackground>
  )
}
