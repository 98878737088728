import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useAppState } from '../../GlobalState'
import { useWindowSize } from '../../hooks/WindowSize'
import { HeaderLink } from '../organisms/Header'
import { PopupContent } from './PopupContent'

const fadeUp = () => keyframes`
    0% { background-color: rgba(0, 0, 0, 0); }
  100% { background-color: rgba(0, 0, 0, 0.5); }
`
const fadeDown = () => keyframes`
    0% { background-color: rgba(0, 0, 0, 0.5); }
  100% { background-color: rgba(0, 0, 0, 0); }
`
const Backdrop = styled.div<{ closing?: boolean; index: number; visible: boolean }>`
  z-index: ${p => (p.visible ? 2023 : -1)};
  position: fixed;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  animation-name: ${p => (p.index > 0 ? '' : p.closing ? fadeDown : fadeUp)};
  animation-duration: ${p => (p.index > 0 ? '' : p.closing ? 0.2 : 2.0)}s;
`

const moveUp = (props: any) => keyframes`
    0% { margin-top: 100vh }
  100% { margin-top: ${props.fullscreen ? '0' : '92px'} }
`
const moveDown = (props: any) => keyframes`
  100% { margin-top: 100vh }
    0% { margin-top: ${props.fullscreen ? '0' : '92px'} }
`
const ModalBackground = styled.div<{ fullscreen?: boolean; theme?: string; closing?: boolean; index: number }>`
  position: relative;
  width: ${p => (p.fullscreen ? '96vw' : '880px')};
  max-width: 90vw;
  margin: ${p => (p.fullscreen ? '0' : '92px')} auto 0;
  height: 100%;
  background-color: ${p => (p.theme === 'themetikka' ? '#fff' : '#131313')};
  color: ${p => (p.theme === 'themetikka' ? '#000' : '#fff')};
  border-radius: 20px 20px 0 0;
  animation-name: ${p => (p.index > 0 ? '' : p.closing ? moveDown : moveUp)};
  animation-duration: ${p => (p.index > 0 ? '' : p.closing ? 0.2 : 0.5)}s;
`

const FullscreenBackdrop = styled.div`
  z-index: 2023;
  position: fixed;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow-y: scroll;
`

const FullscreenMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
`

export const FullscreenContainer = styled.div<{ height: number }>`
  position: fixed;
  top: 64px;
  left: 0;
  display: flex;
  height: ${p => p.height}px;
  width: 100vw;
  z-index: 2023;
`

export const Modal = (): JSX.Element | null => {
  const { state, actions } = useAppState()
  const [closing, setClosing] = useState(false)
  const { height } = useWindowSize()

  useEffect(() => {
    console.log('popupContent changed', state.popupContent)
  }, [state.popupContent])

  const close = () => {
    if (state.popupContent.length > 1) {
      // no animation if there's multiple stacked popups open
      actions.closePopup()
      return
    }
    setClosing(true)
    setTimeout(() => {
      actions.closePopup()
      setClosing(false)
    }, 200)
  }

  if (state.popupContent.length === 0) return null
  const elements = state.popupContent.map((popupContent, i) => {
    const isLast = i + 1 === state.popupContent.length
    const backArrow = state.popupContent.length > 1

    if (popupContent.fullscreen) {
      return (
        <FullscreenBackdrop key={popupContent.id}>
          <FullscreenMenu>
            <HeaderLink onClick={() => actions.closePopup()}>&lt; Back</HeaderLink>
          </FullscreenMenu>
          <FullscreenContainer height={height}>
            <PopupContent key={popupContent.id} content={popupContent} onClose={close} />
          </FullscreenContainer>
        </FullscreenBackdrop>
      )
    }
    return (
      <Backdrop onClick={close} closing={closing} key={popupContent.id} index={i} visible={isLast}>
        <ModalBackground
          onClick={e => e.stopPropagation()}
          theme={popupContent.theme}
          fullscreen={popupContent.fullscreen}
          closing={closing}
          index={i}
        >
          <PopupContent content={popupContent} onClose={close} backArrow={backArrow} />
        </ModalBackground>
      </Backdrop>
    )
  })
  return <>{elements}</>
}
