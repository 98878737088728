import React, { useState } from 'react'
import styled from 'styled-components'
import YouTube from 'react-youtube'
import { Options } from 'react-youtube'
import { useWindowSize } from '../../hooks/WindowSize'
import { Heading2 } from '../atoms/Typography'

const LoadingScreen = styled.div<{ w: number; h: number }>`
  position: relative;
  width: ${p => p.w}px;
  height: ${p => p.h}px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface YouTubePlayerProps {
  videoId: string
  mute?: boolean
  loop?: boolean
}
export const YouTubePlayer = (props: YouTubePlayerProps): JSX.Element => {
  const [loading, setLoading] = useState(true)
  const { width, height } = useWindowSize()

  const opts: Options = {
    width: String(width),
    height: String(height),
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      rel: 0,
      fs: 1,
      start: 0,
      mute: props.mute ? 1 : 0,
      loop: props.loop ? 1 : 0,
    },
  }
  return (
    <>
      {loading ? (
        <LoadingScreen w={width} h={height}>
          <Heading2 uppercase>Loading...</Heading2>
        </LoadingScreen>
      ) : null}
      <YouTube videoId={props.videoId} opts={opts} onReady={() => setLoading(false)} />
    </>
  )
}
