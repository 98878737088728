import React from 'react'
import styled from 'styled-components'
import { ArrowLeftSvg, ArrowRightSvg } from '../../assets/arrow'

const ArrowContainer = styled.div`
  position: fixed;
  top: 50%;
  z-index: 2001;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
`
const ArrowLeft = ({ onClick }: { onClick: () => void }) => {
  return (
    <ArrowContainer style={{ left: 0 }} onClick={onClick}>
      <ArrowLeftSvg />
    </ArrowContainer>
  )
}
const ArrowRight = ({ onClick }: { onClick: () => void }) => {
  return (
    <ArrowContainer style={{ right: 0 }} onClick={onClick}>
      <ArrowRightSvg />
    </ArrowContainer>
  )
}

interface ArrowNavigationProps {
  panLeft: () => void
  panRight: () => void
}
export const ArrowNavigation = ({ panLeft, panRight }: ArrowNavigationProps): JSX.Element => {
  return (
    <>
      <ArrowLeft onClick={panLeft} />
      <ArrowRight onClick={panRight} />
    </>
  )
}
