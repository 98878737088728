import { createGlobalStyle } from 'styled-components'

const cdn = 'https://cdn1.sako.fi/sites/all/themes/sako_omega_4/sass/fonts'
export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'DINNextW01Regular';
    src: url(${cdn}/da088ecc-a53c-4831-a95b-6fd81b5b338a.eot?#iefix);
    src: url(${cdn}/da088ecc-a53c-4831-a95b-6fd81b5b338a.eot?#iefix) format('eot'),
      url(${cdn}/c5a7f89e-15b6-49a9-8259-5ea665e72191.woff) format('woff'),
      url(${cdn}/868c94b5-ca49-46b7-b62b-297f8d861125.ttf) format('truetype'),
      url(${cdn}/e3145f65-29b5-4f7f-8c6e-2dc135021b6a.svg#e3145f65-29b5-4f7f-8c6e-2dc135021b6a) format('svg');
  }
  @font-face {
    font-family: 'DINNextW01Medium';
    src: url(${cdn}/324dee78-6585-4c13-8ca3-5817cc600d84.eot?#iefix);
    src: url(${cdn}/324dee78-6585-4c13-8ca3-5817cc600d84.eot?#iefix) format('eot'),
      url(${cdn}/4bf0ead4-e61b-4992-832b-6ff05828d99f.woff) format('woff'),
      url(${cdn}/73713a0b-8f6a-4c4e-b8a8-7cf238e632f6.ttf) format('truetype'),
      url(${cdn}/b90b6bdf-15e2-4544-9fe0-92c79fff0ecd.svg#b90b6bdf-15e2-4544-9fe0-92c79fff0ecd) format('svg');
  }
  @font-face {
    font-family: 'DINNextW01Bold';
    src: url(${cdn}/b32ab78b-6c51-4bd3-bc37-4edcec0331f8.eot?#iefix);
    src: url(${cdn}/b32ab78b-6c51-4bd3-bc37-4edcec0331f8.eot?#iefix) format('eot'),
      url(${cdn}/9949035f-6226-4080-a14a-bf7d94ffb1e1.woff) format('woff'),
      url(${cdn}/6241bd09-15c2-485e-9796-9db8d0aa6974.ttf) format('truetype'),
      url(${cdn}/af94da3e-4232-416a-b6bc-f39f85dd5db6.svg#af94da3e-4232-416a-b6bc-f39f85dd5db6) format('svg');
  }
  body {
    font-family: DINNextW01Regular, sans-serif;
    margin: 0;
  }
`
