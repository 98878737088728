import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { EventRegion } from '../../Constants'
import { useAppState } from '../../GlobalState'
import { Heading, HeroText } from '../atoms/Typography'

const Container = styled.div`
  position: fixed;
  top: 96px;
  left: 32px;
  width: 235px;

  padding: 16px 32px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 16px;
  z-index: 2010;

  @media (max-width: 1100px) {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 auto 2em;
    width: 480px;
    max-width: 80vw;
  }
`

const Content = styled.div`
  text-align: center;
`

const Countdown = styled(Heading)`
  margin: 0;
  font-family: DINNextW01Regular, sans-serif;
  font-size: 28px;
  line-height: 32px;
  white-space: nowrap;
  @media (max-width: 920px) {
    font-size: 20px;
  }
`
const Text = styled(HeroText)`
  margin: 4px 0 12px;
  font-size: 16px;
  line-height: 1.4em;
  @media (max-width: 920px) {
    font-size: 14px;
  }
`

const pad = (i: number) => {
  return i < 10 ? '0' + i : i
}

const friendlyDate = (delta: number): string => {
  const ss = pad(delta % 60)
  delta = Math.floor(delta / 60)
  const mm = pad(delta % 60)
  delta = Math.floor(delta / 60)
  const hh = pad(delta % 24)
  delta = Math.floor(delta / 24)
  const days = delta
  if (days > 0) {
    return `${days}d ${hh}:${mm}:${ss}`
  }
  return `${hh}:${mm}:${ss}`
}

const calculateDelta = (target: Date): number => {
  const now = new Date()
  return Math.ceil((target.getTime() - now.getTime()) / 1000)
}

export const CountdownLayer = (): JSX.Element | null => {
  const { state } = useAppState()
  const [deltaSeconds, setDeltaSeconds] = useState(0)
  const name = `REACT_APP_AT_${state.eventRegion?.toUpperCase()}`
  const target = new Date(process.env[name] || '2000-01-01T00:00:00+0000')
  const eventDurationSeconds = 60 * 60 * 6 // 6 hours
  const until = state.eventRegion === EventRegion.Private ? 'April 14 2021' : 'April 15 2021'

  useEffect(() => {
    const interval = setInterval(() => {
      setDeltaSeconds(calculateDelta(target))
    }, 500)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Container>
      <Content>
        {-deltaSeconds > eventDurationSeconds ? (
          <Text>The live event has ended. Event recording is available until {until}.</Text>
        ) : deltaSeconds < 0 ? (
          <>
            <Countdown>LIVE</Countdown>
            <Text>Live event started at {target.toString()}</Text>
          </>
        ) : (
          <>
            <Countdown>{friendlyDate(deltaSeconds)}</Countdown>
            <Text>Live event starting at {target.toString()}</Text>
          </>
        )}
      </Content>
    </Container>
  )
}
