import styled from 'styled-components'

export const Heading = styled.h1<{ uppercase?: boolean }>`
  font-family: DINNextW01Bold, sans-serif;
  text-transform: ${p => (p.uppercase ? 'uppercase' : 'none')};
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.44px;
  margin: 0.5em 0;
  @media (min-width: 480px) {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.44px;
  }
  @media (min-width: 768px) {
    font-size: 54px;
    line-height: 66px;
    letter-spacing: 0.6px;
  }
  @media (min-width: 1024px) {
    font-size: 54px;
    line-height: 66px;
    letter-spacing: 0.6px;
  }
`

export const Heading2 = styled.h1<{ uppercase?: boolean }>`
  font-family: DINNextW01Medium, sans-serif;
  text-transform: ${p => (p.uppercase ? 'uppercase' : 'none')};
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 1px;
  margin: 1em 0;
  @media (min-width: 480px) {
    font-size: 24px;
    line-height: 29px;
  }
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 29px;
  }
  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 29px;
  }
`

export const HeroText = styled.div<{ uppercase?: boolean }>`
  font-family: Georgia, serif;
  font-style: italic;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 1em;
  @media (min-width: 480px) {
    font-size: 18px;
    line-height: 32px;
  }
  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 32px;
  }
  @media (min-width: 1024px) {
    font-size: 22px;
    line-height: 32px;
  }
`

export const Paragraph = styled.div`
  font-family: DINNextW01Regular, sans-serif;
  font-size: 14px;
  line-height: 29px;
  margin-bottom: 1em;
  @media (min-width: 480px) {
    font-size: 17px;
    line-height: 26px;
  }
  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 26px;
  }
  @media (min-width: 1024px) {
    font-size: 17px;
    line-height: 26px;
  }
`

export const Link = styled.a`
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
`
