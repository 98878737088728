import React from 'react'

const ArrowDownSvg = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width='64px' height='32px' viewBox='0 0 64 32' {...props}>
    <polygon points='0,0 64,0 32,31' fill='currentcolor' />
  </svg>
)

export const ArrowLeftSvg = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <ArrowDownSvg style={{ transform: 'rotate(90deg)' }} {...props} />
)

export const ArrowRightSvg = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <ArrowDownSvg style={{ transform: 'rotate(-90deg)' }} {...props} />
)
