import React from 'react'
import styled from 'styled-components'
import { useAppState } from '../../GlobalState'
import { Spinner } from '../atoms/Spinner'

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2099;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  color: #fff;
  width: 65vw;
  margin-top: -25vh;
  text-align: center;
`

export const PanoramaLoadingOverlay = (): JSX.Element | null => {
  const { state } = useAppState()
  if (state.panoLoading) {
    return (
      <Backdrop>
        <Content>
          <Spinner />
        </Content>
      </Backdrop>
    )
  }
  return null
}
