import React from 'react'
import styled from 'styled-components'
import { EventRegion } from '../../Constants'
import { useAppState } from '../../GlobalState'
import { Button } from '../atoms/Button'
import { Heading, HeroText } from '../atoms/Typography'

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2010;
  display: flex;
  justify-content: center;
  overflow: scroll;
`

const Content = styled.div`
  color: #fff;
  max-width: 680px;
  width: 80vw;
  margin-top: 128px;
  text-align: center;
`

const RegionButton = styled(Button)`
  display: block;
  margin: 16px auto;
  width: 450px;
  max-width: 80vw;
`

const Padding = styled.div`
  padding-top: 25vh;
`

export const LiveEventLanding = (): JSX.Element | null => {
  const { state, actions } = useAppState()
  const reload = (region: EventRegion) => {
    actions.setEventRegion(region)
  }
  if (state.eventRegion) return null
  return (
    <Backdrop>
      <Content>
        <Heading uppercase>Sako 100 birthday celebration event</Heading>
        <HeroText>Choose your region:</HeroText>
        <RegionButton onClick={() => reload(EventRegion.EU)}>Europe, Middle East and Asia</RegionButton>
        <RegionButton onClick={() => reload(EventRegion.US)}>US and Americas</RegionButton>
        <Padding />
      </Content>
    </Backdrop>
  )
}
