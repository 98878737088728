import { ContentId, Hotspot } from '../Constants'
import premiumConfigurator from './images/premium-configurator.png'
import premiumStock from './images/premium-stock.png'
import premiumWood from './images/premium-wood.png'
import premiumEngraving from './images/premium-engraving.png'
import premiumDetails from './images/premium-details.png'
import moose from './images/moose.png'
import demandBackground from './images/demand-background.png'
import s20Background from './images/s20-background.png'
import premiumBackground from './images/premium-background.png'
import sako85Background from './images/sako85-background.png'
import historicBackground from './images/historic-background.png'
import tikkaBackground from './images/tikka-background.png'
import trgBackground from './images/trg-background.png'
import cartridgesBackground from './images/cartridges-background.png'
import cartridgesImage from './images/cartridges-image.png'
import { gunData, GunDataContent, GunFamily, GunId } from './GunData'
import videoGalleryBackground from './images/videos-background.png'
//import videoGallerySako from './images/videos-sako.png'
import videoGalleryCraftmanship from './images/videos-craftmanship.png'
import videoGalleryCartridges from './images/videos-cartridges.png'
import videoGalleryAccuracy from './images/videos-accuracy.png'
import videoGalleryAnthem from './images/videos-anthem.png'

export enum SalesforceFormId {
  Sako = '6020979b-6539-466c-ba01-59abf7d4220c',
  Tikka = '7ad7c3d4-4f8d-4a93-9983-6260acd0d603',
}

export interface Content {
  id: string
  fullscreen?: boolean
  tagline?: string
  hero: Hero
  body?: string
  image?: string
  youtube?: string
  vimeo?: string
  autoPlay?: boolean
  productDetails: ProductDetail[]
  productGrid: ProductGridData[]
  buttons?: ProductButton[]
  salesforceFormId?: string
  gun?: GunDataContent
  subscribe?: SalesforceFormId
  theme?: string
}
export interface Hero {
  backgroundImage?: string
  heading?: string
  headingIcon?: string
  logo?: string
  buttons?: ProductButton[]
  text?: string
}
export interface ProductDetail {
  image: string
  heading: string
  description: string
}
export interface ProductGridDataGun {
  type: 'gun'
  id: string
  image: string
  label: string
  year?: number
  alone?: boolean
}
export interface ProductGridDataVideo {
  type: 'video'
  id: string
  image: string
  label: string
}
export interface ProductGridDataDivider {
  type: 'divider'
  id: string // the label e.g. '1920s' or '1930s'
}
export type ProductGridData = ProductGridDataGun | ProductGridDataVideo | ProductGridDataDivider
export type ProductButton = ProductButtonInternal | ProductButtonExternal
export interface ProductButtonInternal {
  type: 'internal'
  label: string
  popupTarget: string
}
export interface ProductButtonExternal {
  type: 'external'
  label: string
  onClick: () => void
  analyticsId: string
}

const gridData = (id: string, alone?: boolean): ProductGridData => {
  return {
    type: 'gun',
    id,
    image: gunData[id].gridImage,
    label: gunData[id].label,
    year: gunData[id].year,
    alone,
  }
}

export const getContent = (id: string): Content => {
  const content: Content = {
    id,
    hero: {},
    productDetails: [],
    productGrid: [],
  }

  const data = gunData[id] || {}
  const isGunData = !!data.id

  switch (id) {
    case Hotspot.VideoGallery:
      content.hero = {
        backgroundImage: videoGalleryBackground,
        heading: 'Videos',
      }
      content.productGrid = [
        // {
        //   type: 'video',
        //   id: ContentId.BerettaEventVideo,
        //   image: videoGallerySako,
        //   label: 'Beretta Holding´s Sako 100 years anniversary gala 2021',
        // },
        // {
        //   type: 'video',
        //   id: ContentId.AnniversaryEventVideo,
        //   image: videoGallerySako,
        //   label: 'Sako 100 birthday celebration event',
        // },
        {
          type: 'video',
          id: ContentId.AnthemVideo,
          image: videoGalleryAnthem,
          label: 'Sako 100 years anthem',
        },
        {
          type: 'video',
          id: ContentId.CrafmanshipVideo,
          image: videoGalleryCraftmanship,
          label: 'Sako — craftmanship & modern technology',
        },
        {
          type: 'video',
          id: ContentId.AccuracyVideo,
          image: videoGalleryAccuracy,
          label: 'Sako — world class accuracy',
        },
        {
          type: 'video',
          id: ContentId.CartridgesMakesEveryRifleBetterVideo,
          image: videoGalleryCartridges,
          label: 'Sako cartridges — makes every rifle better',
        },
      ]
      break

    case ContentId.MooseVideo:
      content.fullscreen = true
      content.youtube = 'ek8zID-v5nE'
      break

    case ContentId.DocumentaryVideo:
      content.fullscreen = true
      content.vimeo = 'https://player.vimeo.com/video/491540004'
      content.autoPlay = true
      break

    case ContentId.DocumentaryFinVideo:
      content.fullscreen = true
      content.vimeo = 'https://player.vimeo.com/video/473796169'
      content.autoPlay = true
      break

    case ContentId.LifelongJourneyVideo:
      content.fullscreen = true
      content.youtube = 'DOnh5W3_U1c'
      break

    case ContentId.PowerheadBladeVideo:
      content.fullscreen = true
      content.youtube = '65-8rs5XIlQ'
      break

    case ContentId.TikkaT1xMTRVideo:
      content.fullscreen = true
      content.youtube = 'obBB70yy8eY'
      break

    case ContentId.TikkaT3xTactA1Video:
      content.fullscreen = true
      content.youtube = 'IN_8VBrTTjQ'
      break

    case ContentId.TRGVideo:
      content.fullscreen = true
      content.youtube = 'ueuvjkxtjcc'
      break

    case ContentId.S20AccessoriesVideo:
      content.fullscreen = true
      content.youtube = 'qjmtbO4i8-Q'
      break

    case ContentId.SakoBrandVideo:
      content.fullscreen = true
      content.youtube = 'gaev59eo634'
      break

    case ContentId.Sako85HighlightsVideo:
      content.fullscreen = true
      content.youtube = 'BLIfFXdHR8Q'
      break

    case ContentId.Sako85CustomHighlightsVideo:
      content.fullscreen = true
      content.youtube = 'ig0891JwktM'
      break

    case ContentId.TikkaBrandVideo:
      content.fullscreen = true
      content.youtube = 'otGJTEGQy9k'
      break

    case ContentId.S20CompleteStoryVideo:
      content.fullscreen = true
      content.youtube = '9kEs8Jd2xwo'
      break

    case ContentId.BerettaEventVideo:
      content.fullscreen = true
      content.vimeo = 'https://player.vimeo.com/video/531233739'
      content.autoPlay = false
      break

    case ContentId.AnniversaryEventVideo:
      content.fullscreen = true
      content.vimeo = 'https://player.vimeo.com/video/531637569'
      content.autoPlay = false
      break

    case ContentId.CartridgesMakesEveryRifleBetterVideo:
      content.fullscreen = true
      content.youtube = 'Xt4UsB1Aofo'
      break

    case ContentId.CrafmanshipVideo:
      content.fullscreen = true
      content.youtube = '3RiiF2Pq8xs'
      break

    case ContentId.AccuracyVideo:
      content.fullscreen = true
      content.youtube = 'HgvK_6jBXRQ'
      break

    case ContentId.AnthemVideo:
      content.fullscreen = true
      content.youtube = 'd-DzLCni0l4'
      break

    case Hotspot.ExhibitionTvScreen:
      content.fullscreen = true
      content.youtube = 'SaNe2fKhe_o'
      break

    case Hotspot.LeftTvScreen:
      content.fullscreen = true
      content.youtube = 'veeP6efL1W4'
      break

    case Hotspot.RightTvScreen:
      content.fullscreen = true
      content.youtube = 'Wky75db7FaY'
      break

    case SalesforceFormId.Sako:
      content.hero = {
        heading: 'Sako Newsletter',
      }
      content.salesforceFormId = SalesforceFormId.Sako
      break

    case SalesforceFormId.Tikka:
      content.hero = {
        heading: 'Tikka Newsletter',
      }
      content.salesforceFormId = SalesforceFormId.Tikka
      break

    case Hotspot.ExhibitionSako85:
      content.hero = {
        backgroundImage: sako85Background,
        heading: 'Sako 85 Rifles',
        text: `Versatility is the mark of true excellence. The Sako 85 rifles are built with demanding design criteria, based on long tradition and newest innovations, allowing you to choose the best option for any hunting or training purpose.`,
        buttons: [
          {
            type: 'internal',
            label: 'Watch the film',
            popupTarget: ContentId.SakoBrandVideo,
          },
          {
            type: 'internal',
            label: 'Series highlights',
            popupTarget: ContentId.Sako85HighlightsVideo,
          },
        ],
      }
      content.productGrid = [
        gridData(GunId.Sako85Bavarian),
        gridData(GunId.Sako85BlackBear),
        gridData(GunId.Sako85Carbonlight),
        gridData(GunId.Sako85Carbonwolf),
        gridData(GunId.Sako85Finnlight2),
        gridData(GunId.Sako85Grizzly),
        gridData(GunId.Sako85Safari),
        gridData(GunId.Sako85SyntheticBlack),
      ]
      content.subscribe = SalesforceFormId.Sako
      break

    case Hotspot.ExhibitionHistoric:
      content.hero = {
        backgroundImage: historicBackground,
        heading: 'Legendary historical models',
        text: `Over the past hundred years, we have invested in a considerable amount of effort in
        ambitious product development and innovation. We are proud of each and every one of our rifle
        models, but of course some are superior to others from the history, our customers and commercial
        success point of views. Here is a selection of our most legendary models.`,
      }
      content.productGrid = [
        { type: 'divider', id: '1920s' },
        gridData(GunId.HistoricLotta),
        gridData(GunId.HistoricSuomi),
        gridData(GunId.HistoricPystykorvaM28, true),
        { type: 'divider', id: '1930s' },
        gridData(GunId.HistoricPystykorvaM2830),
        gridData(GunId.HistoricUkkopekka),
        { type: 'divider', id: '1940s' },
        gridData(GunId.HistoricL46, true),
        { type: 'divider', id: '1950s' },
        gridData(GunId.HistoricL57, true),
        { type: 'divider', id: '1960s' },
        gridData(GunId.HistoricVixen),
        gridData(GunId.HistoricFinnbear),
        gridData(GunId.HistoricRK62),
        gridData(GunId.HistoricFinnwolf),
        gridData(GunId.HistoricTikkaM55),
        gridData(GunId.HistoricTikkaM65),
        { type: 'divider', id: '1970s' },
        gridData(GunId.HistoricTikkaM07, true),
        { type: 'divider', id: '1980s' },
        gridData(GunId.HistoricTriace),
        gridData(GunId.HistoricTRG21),
        gridData(GunId.HistoricTikkaM558, true),
        { type: 'divider', id: '1990s' },
        gridData(GunId.HistoricRK95),
        gridData(GunId.Historic75),
        gridData(GunId.HistoricTRG42, true),
        { type: 'divider', id: '2000s' },
        gridData(GunId.HistoricTikka512S),
        gridData(GunId.HistoricTikkaT3),
        gridData(GunId.HistoricTikkaT3Tactical),
        gridData(GunId.HistoricSakoQuad),
        gridData(GunId.HistoricSako85),
        gridData(GunId.HistoricSakoA7),
        { type: 'divider', id: '2010s' },
        gridData(GunId.HistoricSakoTRGM10),
        gridData(GunId.HistoricTikkaT3x),
        gridData(GunId.HistoricTikkaT3xTactA1Military),
        gridData(GunId.HistoricTikkaT3xTactA1Civilian),
        gridData(GunId.HistoricTikkaC19),
        gridData(GunId.HistoricTikkaT1x),
        { type: 'divider', id: '2020s' },
        gridData(GunId.HistoricSakoS20, true),
      ]
      content.subscribe = SalesforceFormId.Sako
      break

    case Hotspot.ExhibitionS20:
      content.hero = {
        backgroundImage: s20Background,
        heading: 'Sako S20',
        text: `The new Sako S20 is the first true hybrid rifle, the one that both hunters and precision shooters can embrace. It's designed for passionate shooters who want to take and stay in control in every situation. The modular structure adapts to changing lifestyles, making S20 a truly future-proof bolt action rifle. Currently available Sako S20 caliber options: 6.5 Creedmoor, .308 Win, .243 Win, .30-06 Spring, .270 Win, 6.5 PRC, 7mm Rem Mag, .300 Win Mag.`,
      }
      content.productGrid = [gridData(GunId.SakoS20Hunting), gridData(GunId.SakoS20Precision)]
      content.subscribe = SalesforceFormId.Sako
      break

    case Hotspot.ExhibitionTRG:
      content.hero = {
        backgroundImage: trgBackground,
        heading: 'Sako TRG',
        text: `The Sako TRG an accuracy concept designed to accomplish a single-minded mission: to hit the target – whatever it takes. It is a result of Sako’s innovation laboratories, giving you performance that surpasses the highest demands for accuracy, reliability and versatility.`,
        buttons: [
          {
            type: 'internal',
            label: 'Watch highlights video',
            popupTarget: ContentId.TRGVideo,
          },
        ],
      }
      content.body = `Compositions include black, forest green or desert tan colored aluminum-reinforced stocks with various barrel and action finishes. The TRG Accuracy concept serves several military and law enforcement organizations as their primary sniper weapon system. The rifle is also used by long-range competition shooters and is a CISM competition winner.`
      content.productGrid = [gridData(GunId.SakoTRG42), gridData(GunId.SakoTRGM10)]
      content.subscribe = SalesforceFormId.Sako
      break

    case Hotspot.ExhibitionTikka:
      content.theme = 'themetikka'
      content.hero = {
        backgroundImage: tikkaBackground,
        logo: 'tikka',
        text: `Tikka has a long legacy and the Tikka rifles have played an important role in Sako’s
        now 100-year-long story. The first-ever Tikkakoski factory was established in 1893
        in Jyväskylä, Finland and the factory started manufacturing gun components as
        early as 1918. Tikkakoski Oy and Sako cooperated on a prototype rifle series in 1981
        and the rest is history: the milestone of the millionth Tikka rifle produced was
        reached in the 2020.
        <br/><br/>
        Building on Sako’s 100-year-long firearm manufacturing tradition, Tikka is able to
        meet the requirements of modern hunters by combining traditional gunsmith
        know-how with advanced technology.  All Tikka rifles are engineered and manufactured by Sako,
        in Finland. We take pride in our products and deliver what we promise. Tikka rifles are both
        innovative and durable, but most importantly, they can be relied on under any circumstances.
        <br/><br/>        
        A rifle comes with a purpose – to successfully hit the target. When it comes to accuracy and
        performance, Tikka is second to none.
        `,
        buttons: [
          {
            type: 'internal',
            label: 'Watch video',
            popupTarget: ContentId.TikkaBrandVideo,
          },
        ],
      }
      content.tagline = 'Tikka — never compromise'
      content.productGrid = [
        gridData(GunId.TikkaT1xMTR),
        gridData(GunId.TikkaT3xCompactTactical),
        gridData(GunId.TikkaT3xHunter),
        gridData(GunId.TikkaT3xLitePolyfade),
        gridData(GunId.TikkaT3xLiteRoughtech),
        gridData(GunId.TikkaT3xTactA1),
        gridData(GunId.TikkaT3xUPR),
        gridData(GunId.TikkaT3xLiteVeilAlpine),
      ]
      content.subscribe = SalesforceFormId.Tikka
      break

    case Hotspot.ExhibitionCartridges:
      content.hero = {
        heading: 'Powerhead Blade',
        headingIcon: 'green-rectangle',
        backgroundImage: cartridgesBackground,
        buttons: [
          {
            type: 'internal',
            label: 'Watch the film',
            popupTarget: ContentId.PowerheadBladeVideo,
          },
        ],
        text: `Sako has developed the new lead-free BLADE bullet to meet the needs of the most demanding lead-free hunters. One of the challenges in development of the bullet with alternative materials has been the inadequate performance either situations: not expanding for small game/long range impacts or fragmenting down on close situation/bone hits. With its Blade Tip, 100% pure ductile copper and 5-Stage terminal architecture, SAKO Blade offers excellent performance on different shooting ranges and various sizes of game.`,
      }
      content.image = cartridgesImage
      content.subscribe = SalesforceFormId.Sako
      break

    case Hotspot.ExhibitionCelebrationModel:
      content.hero = {
        heading: 'Anniversary launch — The peak of rifle evolution',
        text: 'Stay tuned!',
      }
      content.subscribe = SalesforceFormId.Sako
      break

    case Hotspot.ExhibitionPremium:
      content.hero = {
        backgroundImage: premiumBackground,
        heading: 'Sako Premium',
        text: 'Handmade and exclusive Sako Premium rifles are the perfect embodiments of the Sako spirit',
      }
      content.body = `A rifle is so much more than a means to an end. It’s a reflection of who you are and as individual as you are. With the Sako Custom concept, you can customize your very own Sako 85 rifle based on your personal needs and preferences. We offer thousands of different combinations for you to choose from. Our talented gunsmiths in Riihimäki Finland will then build the rifle according to your choices.`
      content.productDetails = [
        {
          image: premiumConfigurator,
          heading: 'Configurator makes customization easy',
          description: 'With the Sako Custom Configurator you can customize your rifle in multiple ways.',
        },
        {
          image: premiumStock,
          heading: 'Stock model',
          description: 'Select a stock model based on your preferred caliber and hunting style.',
        },
        {
          image: premiumWood,
          heading: 'Wood grade',
          description: `Our globally recognised experts have years of experience crafting and working with wood. This ensures your stock always represents the absolute best of its grade, whether you choose standard grade or upgrade to high or even to our prestigious exhibition grade.`,
        },
        {
          image: premiumEngraving,
          heading: 'Engravings',
          description: `For the finishing touch to your rifle, you can choose between three beautiful engraving options for your rifle's breech, pistol grip plane and the bottom of the maganize.`,
        },
        {
          image: premiumDetails,
          heading: 'Additional details',
          description: `You can also select your preferred open sight, the length of the barrel and trigger mechanism. The material and finish options depend on your chosen stock and engravings.`,
        },
      ]
      content.buttons = [
        {
          type: 'internal',
          label: 'Customization highlights',
          popupTarget: ContentId.Sako85CustomHighlightsVideo,
        },
        {
          type: 'external',
          label: 'Build your custom rifle',
          onClick: () => {
            window.open('https://custom.sako.fi/', 'sakofi')
          },
          analyticsId: 'sako-s20-configurator',
        },
      ]
      content.subscribe = SalesforceFormId.Sako
      break

    case Hotspot.Moose:
      content.hero = {
        backgroundImage: moose,
        heading: 'The Sako Moose',
        text: `The Sako Moose “Hiiden Hirvi” is a bronze sculpture located at Sako Ltd's headquarters courtyard in Riihimäki, Finland. Sketched by the landscape designer Pirita Meskanen and finalized by the art caster Jorma Kärkkäinen, the Sako Moose is a culmination of a 5-year design process. The sculpture was revealed in June 2020.`,
      }
      content.body = `The Sako Moose is modelled after a real animal. Aided by the Sako experts, the artists were able to capture the likeness of one of the largest moose ever shot in Finland. The process itself mirrors the design and production philosophy of Sako, combining the traditional, high quality craftsmanship with the modern technologies such as 3D sculpting.`
      content.buttons = [
        {
          type: 'internal',
          label: 'Watch the documentary',
          popupTarget: ContentId.MooseVideo,
        },
        {
          type: 'external',
          label: 'Learn more',
          onClick: () => {
            window.open('https://issuu.com/sakoltd/docs/the_sako_moose_brochure?fr=sNWQ3NTE1NTIxNTU', 'sakofi')
          },
          analyticsId: 'moose-brochure',
        },
      ]
      break

    case Hotspot.MovieScreen:
    case Hotspot.MovieScreenVideo:
      content.hero = {
        backgroundImage: demandBackground,
        heading: `Demand the perfection`,
        text: `Documentary Aiming for Perfection — The First 100 Years of Sako takes the viewer on a century-long journey to follow Sako’s tremendous growth from a small Defense Army workshop to the world’s leading manufacturer of bolt action rifles.
        <br/><br/>  
        At the same time, the story of Sako is a story about Finland's development from an agricultural and forestry society to a high-tech producer country, where hunting is still a people's industry and hobby.
        <br/><br/>
        The film tells stories e.g. from former and current employees of Sako, hunting experts and social influencers. In addition, members of the current owner of Sako, the world-famous Italian Beretta family company, will appear in the interviews.`,
      }
      content.buttons = [
        {
          type: 'internal',
          label: 'Watch the documentary',
          popupTarget: ContentId.DocumentaryVideo,
        },
        {
          type: 'internal',
          label: 'Katso dokumentti',
          popupTarget: ContentId.DocumentaryFinVideo,
        },
      ]
      break

    default:
      if (isGunData) {
        content.gun = data
        content.theme = data.family === GunFamily.Tikka ? 'themetikka' : undefined
      } else {
        content.hero = {
          heading: 'Not Found',
          text: `Content for this section is not defined.`,
        }
      }
  }

  return content
}
