import React, { VideoHTMLAttributes } from 'react'
import styled from 'styled-components'
import { useWindowSize } from '../../hooks/WindowSize'

const Video = styled.video<{ h: number }>`
  margin-top: 64px;
  object-fit: cover;
  height: ${p => p.h}px;
`

interface NativeVideoProps extends VideoHTMLAttributes<HTMLVideoElement> {
  dummy?: string
}
export const NativeVideo = (props: NativeVideoProps): JSX.Element => {
  const { width, height } = useWindowSize()
  if (props.autoPlay && !props.muted) {
    console.warn('invalid video confiuration: has autoplay on but is not muted!', props)
  }
  return (
    <Video
      src={props.src}
      autoPlay={props.autoPlay}
      controls={props.controls}
      loop={props.loop}
      muted={props.muted}
      playsInline={props.playsInline}
      width={width}
      h={height}
    />
  )
}
