import { createOvermind, Action } from 'overmind'
import { createHook } from 'overmind-react'
import { Content, getContent } from './content/Content'
import { EventRegion } from './Constants'
// import ExpiredStorage from 'expired-storage'
import TagManager from 'react-gtm-module'

const setDebug: Action<boolean> = ({ state }: { state: any }, debug: boolean) => {
  state.debug = debug
}
const setDebugMessage: Action<string> = ({ state }: { state: any }, s: string) => {
  state.debugMessage = s
}
const setEventSite: Action<boolean> = ({ state }: { state: any }, b: boolean) => {
  state.eventSite = b
  console.log('eventSite (%s)', b)
}
const setEventRegion: Action<EventRegion> = ({ state }: { state: any }, region: EventRegion) => {
  state.eventRegion = region
  console.log('eventRegion (%s)', region)
}
const setPanoLoading: Action<boolean> = ({ state }: { state: any }, b: boolean) => {
  state.panoLoading = b
}
const setWelcomeScreen: Action<boolean> = ({ state, actions }: { state: any; actions: any }, b: boolean) => {
  if (b) actions.clearClutter()
  state.welcomeScreen = b
}
const setScene: Action<string> = ({ state }: { state: any }, scene: string) => {
  state.scene = scene
}
const openPopup: Action<string> = ({ state }: { state: any }, contentId: string) => {
  console.debug('openPopup: %s', contentId)
  TagManager.dataLayer({ dataLayer: { event: 'OpenContent', contentId } })
  state.popupContent.push(getContent(contentId))
}
const closePopup: Action = ({ state }: { state: any }) => {
  console.debug('closePopup')
  state.popupContent.pop()
}
const closePopups: Action = ({ state }: { state: any }) => {
  console.debug('closePopups')
  state.popupContent = []
}
const setProductsOverlay: Action<boolean> = ({ state, actions }: { state: any; actions: any }, open: boolean) => {
  if (open) actions.clearClutter()
  state.productsOverlay = open
}
const setMobileMenuOpen: Action<boolean> = ({ state, actions }: { state: any; actions: any }, open: boolean) => {
  if (open) actions.clearClutter()
  state.mobileMenuOpen = open
}
const setRegistered: Action<boolean> = ({ state }: { state: any }, b: boolean) => {
  state.registered = b
}
const setSidebar: Action<string> = ({ state }: { state: any }, sidebar: string) => {
  state.sidebar = sidebar
}
const clearClutter = ({ actions }: { actions: any }) => {
  console.log('clearClutter')
  // when user click somewhere "else", in the panorama space or navigation,
  // all the open popups and other such content should be hidden.
  if (state.popupContent.length > 0) actions.closePopups()
  actions.setWelcomeScreen(false)
  actions.setProductsOverlay(false)
  actions.setMobileMenuOpen(false)
}

export interface State {
  debug: boolean
  debugMessage?: string
  eventSite: boolean
  eventRegion?: EventRegion
  panoLoading: boolean
  welcomeScreen: boolean
  scene?: string
  popupContent: Content[]
  productsOverlay: boolean
  mobileMenuOpen: boolean
  registered: boolean
  sidebar: string
}
const state: State = {
  debug: false,
  debugMessage: undefined,
  eventSite: false,
  eventRegion: undefined,
  panoLoading: true,
  welcomeScreen: true,
  scene: undefined,
  popupContent: [],
  productsOverlay: false,
  mobileMenuOpen: false,
  registered: false,
  sidebar: 'info',
}
const actions = {
  setDebug,
  setDebugMessage,
  setEventSite,
  setEventRegion,
  setPanoLoading,
  setWelcomeScreen,
  setScene,
  openPopup,
  closePopup,
  closePopups,
  setProductsOverlay,
  clearClutter,
  setMobileMenuOpen,
  setRegistered,
  setSidebar,
}
const config = {
  onInitialize: ({ actions }: { actions: any }) => {
    console.log('overmind init...')

    const eventSite = process.env.REACT_APP_EVENT === 'true'
    actions.setEventSite(eventSite)
    if (eventSite) {
      const path = (document.location.pathname || '').toLowerCase()
      let region = undefined
      if (path === '/private' || path === '/beretta') {
        region = EventRegion.Private
      } else if (path === '/emea' || path === '/eu') {
        region = EventRegion.EU
      } else if (path === '/us' || path === '/americas') {
        region = EventRegion.US
      }
      console.log('path (%s) region (%s)', path, region)
      actions.setEventRegion(region)
    }

    // NOTE: registration form not currently used
    // const es = new ExpiredStorage()
    // const registered = es.getJson('registered') || {}
    // console.log('registered', registered)
    // actions.setRegistered(!!registered.email)

    console.log('overmind init done')
  },
  state,
  actions,
  effects: {},
}

export const overmind = createOvermind(config, { devtools: false })
export const useAppState = createHook<typeof config>()
