import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
// import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { Provider } from 'overmind-react'
import { overmind } from './GlobalState'

// eslint-disable-next-line no-undef
const env = process.env

const appEnv = {}
Object.keys(env).map(key => {
  if (key.startsWith('REACT_APP_')) {
    appEnv[key] = env[key]
  }
})
console.debug('the app environment', appEnv)

const gtmId = env.REACT_APP_GTMID
TagManager.initialize({ gtmId })

// const trackingId = env.REACT_APP_GA
// const debug = true
// ReactGA.initialize(trackingId, { debug })
// ReactGA.pageview('/')
// // ReactGA.modalview('/something')
// // ReactGA.event({
// //   category: 'Navigation',
// //   actions: 'Clicked Button',
// //   label: 'About',
// //   value: 123,
// //   nonInteraction: false
// // })

ReactDOM.render(
  <React.StrictMode>
    <Provider value={overmind}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
