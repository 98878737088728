import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { LogoSince1921 } from '../../assets/Logos'
import { useAppState } from '../../GlobalState'
import { HamburgerMenu } from '../../assets/HamburgerMenu'
import { Close } from '../../assets/Close'
import {
  SceneAndHotspot,
  menuDocumentaryCamera,
  startCamera,
  menuVideosCamera,
  menuProductsCamera,
  SidebarWidth,
  Hotspot,
} from '../../Constants'

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 64px;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 2020;
`

const LogoContainer = styled.div<{ clickable?: boolean }>`
  margin: 16px 0 16px 32px;
  cursor: ${p => (p.clickable ? 'pointer' : 'default')};
`

const SidebarContainer = styled.div<{ width: number }>`
  height: 64px;
  width: ${p => p.width}px;
  justify-content: center;
  align-items: center;
  display: flex;
`

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 22px 0 0;
  @media (max-width: 480px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 1024px) {
    display: none;
  }
`

export const HeaderLink = styled.div<{ active?: boolean }>`
  font-family: DINNextW01Regular, sans-serif;
  font-size: 18px;
  color: ${p => (p.active ? '#fff' : '#a4a4a4')};
  text-transform: uppercase;
  letter-spacing: 0.9px;
  cursor: pointer;
  margin: 0 38px 0;
  border-bottom: 1px solid transparent;
  border-color: ${p => (p.active ? '#fff' : 'transparent')};
  &:hover {
    border-color: #fff;
    color: #fff;
  }
`

const WebsiteLink = styled.a`
  font-family: DINNextW01Regular, sans-serif;
  font-size: 18px;
  color: #fff;
  margin: 21px 32px 0 0;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #a4a4a4;
    text-decoration: underline;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`

const MobileMenuToggler = styled.div`
  margin: 20px 24px 0 0;
  cursor: pointer;
  @media (min-width: 1025px) {
    display: none;
  }
`
const MobileMenu = styled.div<{ open: boolean }>`
  display: ${p => (p.open ? 'block' : 'none')};
  position: absolute;
  margin-top: 64px;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 24px 0;
  z-index: 2025;
`
const MobileMenuLink = styled.div`
  font-family: DINNextW01Medium, sans-serif;
  font-size: 32px;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  color: #a4a4a4;
  padding: 24px 0;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
`
const MobileWebsiteLink = styled.a`
  font-size: 18px;
  display: block;
  margin-top: 48px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  &:hover {
    color: #a4a4a4;
    text-decoration: underline;
  }
`

interface HeaderProps {
  moveCamera: (scene: SceneAndHotspot) => void
  liveEvent?: boolean
}
export const Header = (props: HeaderProps): JSX.Element => {
  const { state, actions } = useAppState()
  const [videosActive, setVideosActive] = useState(false)
  const [documentaryActive, setDocumentaryActive] = useState(false)
  const products = () => {
    props.moveCamera(menuProductsCamera)
    actions.setProductsOverlay(!state.productsOverlay)
  }
  const documentary = () => {
    actions.clearClutter()
    props.moveCamera(menuDocumentaryCamera)
  }
  const videos = () => {
    actions.clearClutter()
    props.moveCamera(menuVideosCamera)
  }
  const reload = () => {
    actions.setWelcomeScreen(true)
    props.moveCamera(startCamera)
  }
  const productsActive = (): boolean => {
    return state.productsOverlay === true
  }
  useEffect(() => {
    setVideosActive(state.popupContent.findIndex(p => p.id === Hotspot.VideoGallery) > -1)
    setDocumentaryActive(state.popupContent.findIndex(p => p.id === Hotspot.MovieScreen) > -1)
  }, [state.popupContent.length])
  const toggleMenu = () => {
    const onOff = !state.mobileMenuOpen
    actions.setMobileMenuOpen(onOff)
  }
  if (props.liveEvent) {
    return (
      <Container>
        <LogoContainer>
          <LogoSince1921 />
        </LogoContainer>
        {state.registered && (
          <SidebarContainer width={SidebarWidth}>
            <HeaderLink active={state.sidebar === 'info'} onClick={() => actions.setSidebar('info')}>
              Info
            </HeaderLink>
            <HeaderLink active={state.sidebar === 'chat'} onClick={() => actions.setSidebar('chat')}>
              Chat
            </HeaderLink>
          </SidebarContainer>
        )}
      </Container>
    )
  }
  return (
    <Container>
      <LogoContainer clickable>
        <LogoSince1921 onClick={reload} />
      </LogoContainer>
      <Links>
        <HeaderLink active={productsActive()} onClick={products}>
          Products
        </HeaderLink>
        <HeaderLink active={videosActive} onClick={videos}>
          Videos
        </HeaderLink>
        <HeaderLink active={documentaryActive} onClick={documentary}>
          Documentary
        </HeaderLink>
      </Links>
      <WebsiteLink href='https://sako.fi' target='sakofi'>
        sako.fi
      </WebsiteLink>
      <MobileMenuToggler onClick={toggleMenu}>{state.mobileMenuOpen ? <Close /> : <HamburgerMenu />}</MobileMenuToggler>
      <MobileMenu open={state.mobileMenuOpen}>
        <MobileMenuLink onClick={products}>Products</MobileMenuLink>
        <MobileMenuLink onClick={videos}>Videos</MobileMenuLink>
        <MobileMenuLink onClick={documentary}>Documentary</MobileMenuLink>
        <MobileWebsiteLink href='https://sako.fi' target='sakofi'>
          sako.fi
        </MobileWebsiteLink>
      </MobileMenu>
    </Container>
  )
}
