import React, { SVGProps } from 'react'
import styled from 'styled-components'

export const Close = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width='27px' height='27px' viewBox='7 7 27 27' {...props}>
    <g transform='translate(-322.000000, -14.000000)' fill='#D8D8D8'>
      <g transform='translate(339.000000, 31.000000) rotate(-45.000000) translate(-339.000000, -31.000000) translate(327.000000, 19.000000)'>
        <rect x='0' y='11' width='24' height='2'></rect>
        <rect
          transform='translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)'
          x='0'
          y='11'
          width='24'
          height='2'
        ></rect>
      </g>
    </g>
  </svg>
)

const StyledSvg = styled.div`
  color: rgba(0, 0, 0, 0.4);
  &:hover {
    color: rgba(0, 0, 0, 0.6);
  }
`

export const CloseCircle = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <StyledSvg>
    <svg width='32px' height='32px' viewBox='0 0 34 34' {...props}>
      <circle cx='17' cy='17' r='16' fill='currentcolor' />
      <g transform='translate(-322.000000, -14.000000)' fill='#D8D8D8'>
        <g transform='translate(339.000000, 31.000000) rotate(-45.000000) translate(-339.000000, -31.000000) translate(327.000000, 19.000000)'>
          <rect x='0' y='11' width='24' height='2'></rect>
          <rect
            transform='translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)'
            x='0'
            y='11'
            width='24'
            height='2'
          ></rect>
        </g>
      </g>
    </svg>
  </StyledSvg>
)
