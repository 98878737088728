import React from 'react'

export const HamburgerMenu = (): JSX.Element => (
  <svg width='24px' height='22px' viewBox='0 0 24 22'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-327.000000, -20.000000)' fill='#D8D8D8'>
        <g transform='translate(327.000000, 20.000000)'>
          <rect x='0' y='0' width='24' height='2'></rect>
          <rect x='0' y='10' width='24' height='2'></rect>
          <rect x='0' y='20' width='24' height='2'></rect>
        </g>
      </g>
    </g>
  </svg>
)
