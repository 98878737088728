import React, { useEffect } from 'react'
import styled from 'styled-components'
import useKrpano from 'react-krpano-hooks'
import { useAppState } from './GlobalState'
import { GlobalStyles } from './GlobalStyles'
import { Header } from './components/organisms/Header'
import { ProductNavigation } from './components/organisms/ProductNavigation'
import { CookieConsent } from './components/organisms/CookieConsent'
import { WelcomeOverlayWithVideo } from './components/organisms/WelcomeOverlayWithVideo'
import { ArrowNavigation } from './components/organisms/ArrowNavigation'
import { Modal } from './components/modal/Modal'
import { HotspotScenes, SceneAndHotspot } from './Constants'
import { LiveEvent } from './components/organisms/LiveEvent'
import { PanoramaLoadingOverlay } from './components/organisms/PanoramaLoadingOverlay'

const PanoramaContainer = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 0;
`

const findSceneForHotspot = (hotspotId: string): SceneAndHotspot => {
  const hs = HotspotScenes.find(hs => hs.hotspot === hotspotId)
  if (!hs) {
    console.warn(`HotspotScenes not containing hotspot (${hotspotId})`)
    return HotspotScenes[0]
  }
  return hs
}

const App = (): JSX.Element => {
  const { state, actions } = useAppState()
  const { containerRef, callKrpano, getKrpano } = useKrpano({
    scriptPath: 'krpano/tour.js',
    width: '100%',
    height: '100%',
    globalFunctions: {
      onLoadComplete: () => {
        console.log('onLoadComplete')
        actions.setPanoLoading(false)
      },
      onSceneChange: (scene: string) => {
        console.log('onSceneChange from krpano => %s', scene)
        actions.setScene(scene)
      },
      hotspotOnClick: (id: string) => {
        console.log('hotspotOnClick on %s', id)
        const sceneAndHotspot = findSceneForHotspot(id)
        let delay = 0
        if (state.scene !== sceneAndHotspot.scene) {
          console.log('hotspot scene is %s and current %s', sceneAndHotspot.scene, state.scene)
          moveCamera(sceneAndHotspot)
          delay = 500
        }
        setTimeout(() => actions.openPopup(id), delay)
      },
      panoOnKeyDown: () => {
        console.log('panoOnKeyDown')
      },
      panoOnMouseDown: () => {
        console.log('panoOnMouseDown')
        actions.clearClutter()
      },
    },
  })

  useEffect(() => {
    const currentScene = getKrpano('xml.scene')
    if (currentScene === state.scene) {
      return // ignore scene change when already there
    }
    console.log('useEffect detected state.scene change => %s', state.scene)
    callKrpano(`loadscene('${state.scene}')`)
  }, [state.scene])

  const moveCamera = (scene: SceneAndHotspot) => {
    let slow = true
    const actions = []
    if (scene.scene && scene.scene !== state.scene) {
      actions.push(`loadscene(${scene.scene});`)
      slow = false
    }
    if (scene.hotspot) {
      actions.push(`looktohotspot('${scene.hotspot}',,'linear(${slow ? 180 : 3600000})');`)
    }
    if (actions.length > 0) {
      const cmd = actions.join(' ')
      console.log('callKrpano: %s', cmd)
      callKrpano(cmd)
    }
  }
  const panLeft = () => {
    callKrpano('lookto(calc(view.hlookat - 45), 0)')
  }
  const panRight = () => {
    callKrpano('lookto(calc(view.hlookat + 45), 0)')
  }

  if (state.eventSite) {
    callKrpano('autorotate.start()')
    callKrpano('set_hotspot_visibility(0)')
    return (
      <>
        <GlobalStyles />
        <PanoramaLoadingOverlay />
        <LiveEvent />
        <PanoramaContainer>
          <div ref={containerRef} />
          {state.panoLoading ? null : (
            <>
              <Header moveCamera={moveCamera} liveEvent />
            </>
          )}
        </PanoramaContainer>
        <Modal />
        <CookieConsent />
      </>
    )
  }

  return (
    <>
      <GlobalStyles />
      <PanoramaLoadingOverlay />
      {/* <WelcomeOverlay /> */}
      <WelcomeOverlayWithVideo />
      <PanoramaContainer>
        <div ref={containerRef} />
        {state.panoLoading ? null : (
          <>
            <Header moveCamera={moveCamera} />
            <ProductNavigation moveCamera={moveCamera} />
            <ArrowNavigation panLeft={panLeft} panRight={panRight} />
          </>
        )}
      </PanoramaContainer>
      <Modal />
      <CookieConsent />
    </>
  )
}

export default App
