import React, { SVGProps } from 'react'
import styled from 'styled-components'

const StyledSvg = styled.div`
  color: rgba(0, 0, 0, 0.4);
  &:hover {
    color: rgba(0, 0, 0, 0.6);
  }
`

export const BackArrow = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <StyledSvg>
    <svg width='32px' height='32px' viewBox='0 0 32 32' {...props}>
      <g transform='translate(-1104.000000, -151.000000)'>
        <g transform='translate(1104.000000, 151.000000)'>
          <circle fill='currentcolor' cx={16} cy={16} r={16} />
          <path d='M18,9 L18,15 L27,15 L27,17 L18,17 L18,23 L4,16 L18,9 Z' fill='#D8D8D8' fillRule='nonzero' />
        </g>
      </g>
    </svg>
  </StyledSvg>
)
